/* eslint-disable jsx-a11y/anchor-is-valid */
import { LAYOUT } from "components/constants";
import styled from "styled-components";
import avatar from "../../../../assets/images/avatar.png";
// import { ReactComponent as SearchIcon } from "assets/images/icons/search-normal.svg";
import { ReactComponent as Notifications } from "assets/images/icons/notification.svg";
import { ReactComponent as Settings } from "assets/images/icons/setting-dark.svg";
import { ReactComponent as RequestIcon } from "assets/images/icons/info-circle.svg";
import { ReactComponent as KycIcon } from "assets/images/icons/logout-red.svg";
import { ReactComponent as ArrowDown } from "assets/images/icons/arrow-down.svg";
import { useContext, useEffect, useRef, useState } from "react";
import Cookies from "js-cookie";
import { CONTACT_US_LINK, TOKEN_KEY } from "utils/helpers/constants";
import { useHistory } from "react-router-dom";
import { AuthContext } from "utils/context/AuthContext";

// hambuger menu
const Burger = ({ open, setOpen }) => {
  return (
    <StyledBurger open={open} onClick={() => setOpen(!open)}>
      <div />
      <div />
      <div />
    </StyledBurger>
  );
};
const StyledBurger = styled.button`
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  width: 1.8rem;
  height: 1.8rem;
  background: transparent;
  border: none;
  cursor: pointer;
  padding: 0;
  z-index: 10;

  &:focus {
    outline: none;
  }

  div {
    width: 1.8rem;
    height: 0.2rem;
    background: var(--primary);
    border-radius: 10px;
    transition: all 0.3s linear;
    position: relative;
    transform-origin: 1px;

    :first-of-type {
      transform: ${({ open }) => (open ? "rotate(45deg)" : "rotate(0)")};
    }

    :nth-of-type(2) {
      // opacity: ${({ open }) => (open ? "0" : "1")};
      transform: ${({ open }) => (open ? "translateX(-4px)" : "translateX(0)")};
    }

    :nth-of-type(3) {
      transform: ${({ open }) => (open ? "rotate(-45deg)" : "rotate(0)")};
    }
  }
`;
const Navbar = ({ open, setOpen, navRef }) => {
  const history = useHistory();
  const sidebarLinks = [
    {
      link: "/settings/partner-profile",
      name: "Settings",
      Icon: Settings,
      blank: false,
    },
    {
      link: CONTACT_US_LINK,
      name: "Contact Support",
      blank: true,
      Icon: RequestIcon,
    },
  ];

  const [toggle, setToggle] = useState(false);
  const ref = useRef(null);
  useEffect(() => {
    const checkIfClickedOutside = (e) => {
      // If the menu is open and the clicked target is not within the menu,
      // then close the menu
      if (toggle && ref.current && !ref.current.contains(e.target)) {
        setToggle(false);
      }
    };

    document.addEventListener("mousedown", checkIfClickedOutside);

    return () => {
      // Cleanup the event listener
      document.removeEventListener("mousedown", checkIfClickedOutside);
    };
  }, [toggle]);

  const { firstName, role } = useContext(AuthContext);
  return (
    <Navbar.Wrapper ref={navRef} toggle={toggle}>
      <div className="nav-left">
        <div className="menu-container">
          <Burger open={open} setOpen={setOpen} />
        </div>
        {/* <div className="search">
          <SearchIcon />
          <input type="text" placeholder="Search" />
        </div> */}
      </div>
      <div className="nav-right flex__center">
        <Notifications className="pointer" />
        <img src={avatar} alt="avatar" />
        <div className="mt-4">
          <h4>{firstName}</h4>
          <p>{role?.roleName}</p>
        </div>
        <ArrowDown
          className="pointer mx-4"
          onClick={() => setToggle(!toggle)}
        />
        <div className="nav-dropdown shadow" ref={ref}>
          <ul>
            {sidebarLinks.map(({ link, Icon, name, blank }) => (
              <li key={name}>
                <a
                  href={link}
                  to={link}
                  target={blank ? "_blank" : ""}
                  rel="noreferrer"
                >
                  <Icon />
                  <span>{name}</span>
                </a>
              </li>
            ))}
            <li>
              <a
                className="pointer"
                to="#"
                onClick={() => {
                  Cookies.remove(TOKEN_KEY);
                  history.push("/login");
                }}
              >
                <KycIcon />
                <span>Sign Out</span>
              </a>
            </li>
          </ul>
        </div>
      </div>
    </Navbar.Wrapper>
  );
};

export default Navbar;
Navbar.Wrapper = styled.div`
  position: fixed;
  right: 0;
  top: 0;
  z-index: 2;
  width: calc(100vw - 273px);
  height: 80px;
  box-shadow: 0px 0px 25px rgba(0, 0, 0, 0.06);
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-left: 15px;
  padding-right: 15px;
  background: #ffffff;
  .nav-left {
    display: flex;
    .menu-container {
      display: none;
    }
    h3 {
      font-weight: bold;
      font-size: 24px;
      color: var(--secondary);
      margin-left: 20px;
    }
    .search {
      width: 271px;
      height: 40px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      background: #ffffff;
      border-radius: 6px;

      input {
        width: -webkit-fill-available;
        border: none;
        font-size: 20px;
        height: 30px;
        background: inherit;
        color: var(--secondary);
        margin-left: 10px;
        ::placeholder {
          font-size: 20px;
          color: #d6d7e3;
        }
      }
    }
  }
  .nav-right {
    display: flex;
    position: relative;
    img {
      margin-left: 10px;
      margin-right: 5px;
    }
    div {
      flex-direction: column;
      display: flex;
      justify-content: center;
      align-items: flex-start;
      h4 {
        font-weight: 600;
        margin: 0;
      }
    }

    ${({ toggle }) =>
      toggle
        ? `.nav-dropdown {
          background:var(--white);
      position: absolute;
      width: 200px;
      bottom: -180px;
      li {
        list-style-type: none;
        padding: 10px 15px;
        a {
          text-decoration: none;
          font-size: 14px;
          line-height: 34px;
          /* identical to box height, or 243% */

          color: var(--gray);
          display: flex;
          align-items: center;
          font-weight: 600;
          span {
            margin-left: 10px;
          }
        }
        :hover {
          background: rgba(255, 255, 255, 0.1);
        }
      }
    }`
        : `.nav-dropdown {
      display: none;
    }`}
  }
  @media (max-width: ${LAYOUT.xxLg}) {
    width: 100vw;
    left: 0;
    .nav-left {
      .menu-container {
        display: block;
        margin-top: 10px;
        margin-right: 10px;
      }
    }
  }
  @media (max-width: ${LAYOUT.sm}) {
    .nav-left {
      .menu-container {
        display: block;
        margin-top: 0;
        margin-right: 0;
      }
      .search {
        display: none;
      }
    }
  }
`;
