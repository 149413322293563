import { InsightChartWrapper } from "./InsightChartWrapper";
import { ReactComponent as InfoSecondary } from "assets/images/icons/info-secondary.svg";
import { useState } from "react";
import API from "api";
import StyledDateSelect from "utils/select/DateSelect/StyledDateSelect";
import { useQuery } from "react-query";
import { getCommissionsInsight } from "api/commission";

function InsightChart() {
  const [dateRange, setDateRange] = useState("Today");

  const getInsightQuery = useQuery(
    ["totalInsight", dateRange],
    async () => {
      const response = await API.get(getCommissionsInsight, {
        params: {
          period: dateRange.toLowerCase(),
        },
      });
      return response.data.data;
    },
    {
      keepPreviousData: true,
      refetchOnWindowFocus: false,
    }
  );
  
  return (
    <InsightChartWrapper className="col-md-4 p-4 pl-0">
      <div className="bg--primary p-5 rounded-card text-light ccc">
        <div className="flex__between light-select">
          <div>
            <p className="heading-4">
              <InfoSecondary /> <span className="mx-4">Insight</span>
            </p>
          </div>
          <StyledDateSelect
            wrapperCls="col-9 col-sm-3"
            cls="col-9 col-sm-10"
            name="date-select"
            formLabel="date-select"
            onChange={(e) => {
              setDateRange(e);
            }}
            options={["Today", "Month", "Year"]}
            value={dateRange}
          />
        </div>
        <div className="d-flex py-4">
          <div>
            <p className="black text--xl text-break">
              {getInsightQuery?.data?.percentageIncrease}%
            </p>
          </div>
          <div className="mx-4">
            <p className="heading-4">
              Decrease in <br /> Data Bundle Purchased
            </p>
          </div>
        </div>
        <div>
          <p>
            There’s been a slight decrease in the amount of data purchased over
            the past week.
            <br />
            <br />
            You can do better!
          </p>
        </div>
      </div>
    </InsightChartWrapper>
  );
}

export default InsightChart;
