// HEADINGS

export const walletTableHeadings = [
  "Wallet Name",
  "Total Debit",
  "Total Credit",
  "Wallet Balance",
  "Created At",
];

export const walletTransTableHeadings = [
  "Description",
  "Transaction ID",
  "Amount",
  "Created At",
];

export const walletFiltersDefaultValues = {
  "status[0]": null,
  "status[1]": null,
  "status[2]": null,
  "action[0]": null,
  "action[1]": null,
};
export const walletTransFiltersDefaultValues = {
  "status[0]": null,
  "status[1]": null,
  "status[2]": null,
  "action[0]": null,
  "action[1]": null,
};

// TABLE DATA


export const walletTransTableData = [
  {
    description: 'Wallet Top-up',
    service: 'johny@mail.com',
    id: 'TRANS546***34',
    amount: 4355,
    createdAt: "12 Feb. 2022, 13:27:06 GMT",
  },
  {
    description: 'Wallet Top-up',
    service: 'johny@mail.com',
    id: 'TRANS546***34',
    amount: 4355,
    createdAt: "12 Feb. 2022, 13:27:06 GMT",
  },
  {
    description: 'Wallet Top-up',
    service: 'johny@mail.com',
    id: 'TRANS546***34',
    amount: 4355,
    createdAt: "12 Feb. 2022, 13:27:06 GMT",
  },
  {
    description: 'Wallet Top-up',
    service: 'johny@mail.com',
    id: 'TRANS546***34',
    amount: 4355,
    createdAt: "12 Feb. 2022, 13:27:06 GMT",
  },
  {
    description: 'Wallet Top-up',
    service: 'johny@mail.com',
    id: 'TRANS546***34',
    amount: 4355,
    createdAt: "12 Feb. 2022, 13:27:06 GMT",
  },
  {
    description: 'Wallet Top-up',
    service: 'johny@mail.com',
    id: 'TRANS546***34',
    amount: 4355,
    createdAt: "12 Feb. 2022, 13:27:06 GMT",
  },
  {
    description: 'Wallet Top-up',
    service: 'johny@mail.com',
    id: 'TRANS546***34',
    amount: 4355,
    createdAt: "12 Feb. 2022, 13:27:06 GMT",
  },
  {
    description: 'Wallet Top-up',
    service: 'johny@mail.com',
    id: 'TRANS546***34',
    amount: 4355,
    createdAt: "12 Feb. 2022, 13:27:06 GMT",
  },
  {
    description: 'Wallet Top-up',
    service: 'johny@mail.com',
    id: 'TRANS546***34',
    amount: 4355,
    createdAt: "12 Feb. 2022, 13:27:06 GMT",
  },
  {
    description: 'Wallet Top-up',
    service: 'johny@mail.com',
    id: 'TRANS546***34',
    amount: 4355,
    createdAt: "12 Feb. 2022, 13:27:06 GMT",
  },
  {
    description: 'Wallet Top-up',
    service: 'johny@mail.com',
    id: 'TRANS546***34',
    amount: 4355,
    createdAt: "12 Feb. 2022, 13:27:06 GMT",
  },
  {
    description: 'Wallet Top-up',
    service: 'johny@mail.com',
    id: 'TRANS546***34',
    amount: 4355,
    createdAt: "12 Feb. 2022, 13:27:06 GMT",
  },
];

export const dayOptions = ["Today", "Month", "Year"];


