import API from "api";
import { useState } from "react";
import { useQuery } from "react-query";
import { useHistory } from "react-router-dom";
import UserInfoPill from "utils/pills/UserInfoPill/UserInfoPill";
import SectionWrapper from "utils/section/SectionWrapper/SectionWrapper";
import Table from "utils/Table/Table";
import InsightChart from "./charts/InsightChart/InsightChart";
import MerchantChart from "./charts/MerchantsChart/MerchantChart";
import { merchantsTableHeadings } from "./data";
import MerchantsFilter from "./filters/MerchantsFilter";
import AddMerchantModal from "./modals/AddMerchantModal";
import { getError, timeDateFormat } from "utils/helpers";
import BigSpinner from "utils/Spinner/BigSpinner";
import FailedState from "utils/Modal/Response/FailedState";
import TableFooter from "utils/Table/TableFooter/TableFooter";
import { getMerchants } from "api/merchant";
import { getRowSize } from "storage/sessionStorage";

const Merchants = () => {
  const history = useHistory();
  const [filters, setFilters] = useState({});
  const [showModal, setShowModal] = useState(false);
  const [limit, setLimit] = useState(() => getRowSize());
  const [currentPage, setCurrentPage] = useState(0);
  const toggle = () => {
    setShowModal((prev) => !prev);
  };

  const handleFilters = (filter = {}) => {
    setFilters((prev) => ({ ...prev, ...filter }));
  };

  const clearFilters = (isDate) => {
    setFilters((prev) => ({}));
    setCurrentPage(0);
  };
  const handlePagination = ({ selected }) => {
    setCurrentPage(selected);
  };

  const handleRowClick = (id, rowValues) => {
    const merchantData = getTotalMerchantsQuery?.data?.data?.find(
      (transaction) => transaction?.email === rowValues?.email
    );
    history.push(`/merchants/profile/${merchantData.merchantOrganizationId}`);
  };

  const handleLimitChange = (limit) => {
    setCurrentPage(0);
    setLimit(limit);
  };

  const getTotalMerchantsQuery = useQuery(
    ["totalMerchants", filters, currentPage, limit],
    async () => {
      const response = await API.get(getMerchants, {
        params: { limit, page: currentPage, ...filters },
      });
      return response.data;
    },
    {
      keepPreviousData: true,
      refetchOnWindowFocus: false,
    }
  );

  const dataParser = (data) => {
    return data?.map((report) => {
      const { date, time } = timeDateFormat(report?.createdAt);
      return {
        name: (
          <UserInfoPill
            name={`${report?.firstName} ${report?.lastName}`}
            company={report?.businessName}
          />
        ),
        email: report?.email,
        country: report?.country,
        phone: report?.phoneNumber,
        createdAt: `${date} ${time}`,
      };
    });
    // return []
  };

  const renderByStatus = () => {
    const { isLoading, isError, error, data } = getTotalMerchantsQuery;
    if (isLoading)
      return (
        <div className="w-100 h-500 position-relative flex__column flex__center">
          <BigSpinner />
        </div>
      );
    if (isError)
      return (
        <div className="table__error">
          <FailedState
            onRetry={getTotalMerchantsQuery.refetch}
            title="Error Fetching Payment Transactions"
            errorMsg={getError(error)}
          />
        </div>
      );
    return (
      <>
        {data?.total === 0 ? (
          <div className="px-3 text-uppercase mt-5 text-center">
            <h3>No merchant available</h3>
          </div>
        ) : (
          <>
            <Table
              title="All Merchants"
              data={dataParser(data?.data)}
              headings={merchantsTableHeadings}
              rowClick={handleRowClick}
            />
            <TableFooter
              currentPage={currentPage || 0}
              totalPages={Math.ceil(data.total / 25)}
              handlePagination={handlePagination}
              handleLimitChange={handleLimitChange}
            />
          </>
        )}
      </>
    );
  };
  return (
    <>
      <SectionWrapper
        routeName="Merchants"
        headerChildren={
          <button className="btn btn--primary" onClick={toggle}>
            Invite Merchant
          </button>
        }
      >
        <div className="row mb-4">
          <MerchantChart />
          <InsightChart />
        </div>
        <div className="p-4 bg-white rounded-card">
          <MerchantsFilter
            filters={filters}
            handleFilters={handleFilters}
            clearFilters={clearFilters}
            data={getTotalMerchantsQuery?.data}
          />
          {renderByStatus()}
        </div>
        {showModal && (
          <AddMerchantModal
            toggle={toggle}
            refetch={getTotalMerchantsQuery.refetch}
          />
        )}
      </SectionWrapper>
    </>
  );
};

export default Merchants;
