import { AsYouType, parsePhoneNumber } from "libphonenumber-js";
import { appendPlusToNumber } from "utils/helpers";

export const formValidator = (formObject) => {
    const isObject = (obj) => {
        return Object.prototype.toString.call(obj) === "[object Object]";
    };

    const isEmpty = (fieldValue) => {
        let isEmpty;

        if (typeof fieldValue === "string") {
            isEmpty = fieldValue.trim() === "" || fieldValue === undefined;
        }

        if (typeof fieldValue === "undefined") {
            isEmpty = true;
        }

        if (fieldValue === null) {
            isEmpty = true;
        }

        if (Array.isArray(fieldValue)) {
            isEmpty = fieldValue.length === 0 || fieldValue === undefined;
        }

        if (isObject(fieldValue)) {
            isEmpty = !Object.values(fieldValue).some((val) => val);
        }

        return isEmpty;
    };

    let formErrors = {};
    let isValid = true;

    Object.keys(formObject).forEach((field) => {
        const value = formObject[field];

        if (isEmpty(value)) {
            formErrors[field] = "This field cannot be empty";
        }
        if (field === "email") {
            const isEmailValid =
                /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(
                    formObject[field]
                );
            formErrors[field] = isEmailValid
                ? false
                : "please enter a valid email";
        }
        if (field === "password" || field === "newPassword") {
            if (formObject[field].length < 8) {
                const isPasswordShort = formObject[field].length > 8;
                formErrors[field] = isPasswordShort
                    ? false
                    : "Password must have at least 8 characters";
            } else {
                const isPasswordValid =
                    /(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}/.test(
                        formObject[field]
                    );
                formErrors[field] = isPasswordValid
                    ? false
                    : "Password must have at least 1 uppercase, 1 number and 1 special character";
            }
        }

        if (field === 'confirmPassword') {
            if (formObject[field] !== formObject['password']) {
                formErrors[field] = 'Both Passwords must match';
            }
        }

        if (
            field === 'phoneNumber' ||
            field === 'phone_number' ||
            field === 'recipientPhoneNumber' ||
            field === 'senderPhoneNumber' ||
            field === 'receiverPhoneNumber' ||
            field === 'mobile_no' ||
            field === 'businessPhoneNumber' ||
            field === 'contactPhoneNo' ||
            field === 'directorPhoneNo' ||
            field === 'supportPhoneNumber' ||
            field === 'notification_phone' ||
            field === 'customer_phone'
        ) {

            const value = appendPlusToNumber(formObject[field]);

            if (!value) formErrors[field] = 'Please enter a valid phone number';
            else {
                const formatted = new AsYouType().input(value);
                const countryCode = formatted.split(' ')[0];

                try {
                    const isValid = parsePhoneNumber(formObject[field], {
                        defaultCallingCode: countryCode.replace(/\+/g, ''),
                    }).isValid();

                    if (!isValid) {
                        formErrors[field] = 'Please enter a valid phone number';
                    }
                } catch (e) {
                    formErrors[field] = 'Please enter a valid phone number';
                }
            }
        }
    });

    Object.values(formErrors).forEach((error) => {
        if (error || error.length > 0) isValid = false;
    });

    return { formErrors, isValid };
};
