export const loginUser = "/auth/login-agent";
export const initiateSignup = "/agent/signup/initiate";
export const verifyEmailCode = "/agent/signup/verify/email-code";
export const resendEmailCode = "/agent/signup/resend/email-link";
export const phoneVerification = "/agent/signup/phone-verification";
export const verifyPhone = "/agent/signup/verify/phone-code";
export const resendPhone = "/agent/signup/resend/phone-code";
export const identityTypes = "/agent/signup/list/identity-type";
export const identityVerification = "/agent/signup/identity";
export const addressVerification = "/agent/signup/address-verification";
export const agentImageUpload = "/agent/signup/upload/image";
export const liveness = "/agent/signup/liveness";
export const forgotPassword = "/agent/password/reset";
export const verifyResetPassword = "/agent/password/verify/reset-token";

export const getUserProfile = "/agent/profile";
export const resetPassword = "/agent/password/change";
export const getBusinessType = "/auth/list/business/type";
export const toggle2fa = "/auth/users/set2fa";
export const verifyDisable2fa = "/auth/account/verification/disable-two-fa";
export const verifyLogin2fa = "/auth/account/verification/two-fa/partner";
export const enableAppAuth = "/auth/users/set-app2fa";
export const verifyAppAuth = "/auth/account/authenticator/verify/two-fa";
export const disableAppAuth = "/auth/account/authenticator/disable/two-fa";
export const verifyLoginAppAuth = "/agent/two-fa/authenticator/verify-code";
