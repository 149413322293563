import { useParams } from "react-router-dom";
import { useState } from "react";
import { getError, timeDateFormat, titleCase, toCurrency } from "utils/helpers";
import { PENDING, SUCCESS } from "utils/helpers/constants";
import StatusPill from "utils/pills/StatusPill/StatusPill";
import SectionWrapper from "utils/section/SectionWrapper/SectionWrapper";
import Table from "utils/Table/Table";
import API from "api";
import { transactionsTableHeadings } from "../../data";
import TransactionsFilters from "../../filters/TransactionsFilter";
import { useQuery } from "react-query";
import BigSpinner from "utils/Spinner/BigSpinner";
import FailedState from "utils/Modal/Response/FailedState";
import TableFooter from "utils/Table/TableFooter/TableFooter";
import { getSingleMerchantTransactions } from "api/merchant";
import { getRowSize } from "storage/sessionStorage";

const MerchantTransactions = () => {
  const { id } = useParams();
  const [filters, setFilters] = useState({});
  const [currentPage, setCurrentPage] = useState(0);
  const [limit, setLimit] = useState(() => getRowSize());

  const route = "/merchants";
  const tabs = [
    { label: "Profile", route: `${route}/profile/${id}` },
    { label: "Wallet Transactions", route: `${route}/transactions/${id}` },
  ];

  const handleFilters = (filter = {}) => {
    setFilters((prev) => ({ ...prev, ...filter }));
  };

  const clearFilters = (isDate) => {
    setFilters((prev) => ({}));
    setCurrentPage(0);
  };
  const handlePagination = ({ selected }) => {
    setCurrentPage(selected);
  };

  const handleLimitChange = (limit) => {
    setCurrentPage(0);
    setLimit(limit);
  };

  const getMerchantTransactionsQuery = useQuery(
    ["totalTransactionHistory", filters, currentPage, limit],
    async () => {
      const response = await API.get(`${getSingleMerchantTransactions}/${id}`, {
        params: { page: currentPage, ...filters, limit },
      });
      return response.data;
    },
    {
      keepPreviousData: true,
      refetchOnWindowFocus: false,
    }
  );

  const dataParser = (data) => {
    return data?.map((report) => {
      const { date, time } = timeDateFormat(report?.createdAt);
      return {
        name: titleCase(report?.category),
        transId: report?.transactionReference,
        amount: `${report?.currency} ${toCurrency(report?.amount)}`,
        actionType: report?.actionType,
        balance: `${report?.currency} ${toCurrency(report?.balance)}`,
        status: (
          <StatusPill
            status={report?.status}
            success={SUCCESS}
            warning={PENDING}
          />
        ),
        createdAt: `${date} ${time}`,
      };
    });
  };

  const renderByStatus = () => {
    const { isLoading, isError, error, data } = getMerchantTransactionsQuery;
    if (isLoading)
      return (
        <div className="w-100 h-500 position-relative flex__column flex__center">
          <BigSpinner />
        </div>
      );
    if (isError)
      return (
        <div className="table__error">
          <FailedState
            onRetry={getMerchantTransactionsQuery.refetch}
            title="Error Fetching Merchant Transactions"
            errorMsg={getError(error)}
          />
        </div>
      );
    return (
      <>
        {data.total === 0 ? (
          <div className="px-3 text-uppercase mt-5 text-center">
            <h3>No transaction available</h3>
          </div>
        ) : (
          <>
            <Table
              title="All Merchants"
              data={dataParser(data?.data)}
              headings={transactionsTableHeadings}
              rowClick={handleRowClick}
            />
            <TableFooter
              currentPage={currentPage || 0}
              totalPages={Math.ceil(data.total / 25)}
              handlePagination={handlePagination}
              handleLimitChange={handleLimitChange}
            />
          </>
        )}
      </>
    );
  };
  const handleRowClick = (id, rowValues) => {
    console.log("data", rowValues);
  };
  return (
    <>
      <SectionWrapper
        goBackRoute={{ name: "Merchants", to: "/merchants" }}
        tabs={tabs}
        routeName="Merchant Information"
      >
        <div className="p-4 bg-white rounded">
          <TransactionsFilters
            filters={filters}
            handleFilters={handleFilters}
            clearFilters={clearFilters}
            data={getMerchantTransactionsQuery?.data}
          />
          {renderByStatus()}
        </div>
      </SectionWrapper>
    </>
  );
};

export default MerchantTransactions;
