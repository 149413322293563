import React from "react";
import PhoneInput from "react-phone-input-2";
// import InfoCircleError from '@assets/images/icons/info-circle-error.svg';
import "react-phone-input-2/lib/style.css";

const CustomPhoneInput = ({
  name,
  error,
  disabled,
  code = "ng",
  onChange,
  infoText,
  value,
  cls,
  label,
  required,
  placeholder,
  disableDropdown,
  ...rest
}) => {
  const isError = error ? " error" : "";
  const isRequired = required ? " required" : "";
  const isDisabled = disabled ? " disabled" : "";

  return (
    <div className={`form-group  ${cls}`}>
      {label && (
        <label htmlFor={name} className={`form-group-label ${isRequired}`}>
          {label}
        </label>
      )}
      <div className={`flex__start input-wrapper${isDisabled}${isError}`}>
        <PhoneInput
          name={name}
          country={code.toLowerCase()}
          value={value}
          placeholder={placeholder}
          disabled={disabled}
          onChange={onChange}
          disableDropdown={disableDropdown}
          {...rest}
        />
      </div>

      {infoText && <div className="pt-3 w-100 infoText">{infoText}</div>}
      {typeof error === "string" && error && (
        <small className="input-text-error">
          {/* <InfoCircleError className="me-2" /> */}
          {error}
        </small>
      )}
    </div>
  );
};

export default CustomPhoneInput;
