import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { LoginWrapper } from "./LoginWrapper";
import { formValidator } from "utils/FormValidator/FormValidator";
import blackMadam from "assets/images/black-madam.jpg";
import { ReactComponent as Logo } from "assets/images/icons/bluintouch-logo-dark.svg";
import { ReactComponent as UserTag } from "assets/images/icons/user-tag.svg";
import { ReactComponent as Mobile } from "assets/images/icons/mobile.svg";
import { ReactComponent as Warning } from "assets/images/icons/warning-2.svg";
import { ReactComponent as TickCircle } from "assets/images/icons/tick-circle.svg";
import { Link } from "react-router-dom";
import CustomInputBox from "utils/inputBox/CustomInputBox/CustomInputBox";
import CustomPassword from "utils/inputBox/CustomInputBox/CustomPassword";
import API from "api";
import { useMutation } from "react-query";
import { initiateSignup, resendEmailCode } from "api/auth";
import Alert from "utils/Alert/Alert";
import CustomSpinner from "utils/Spinner/Spinner";
import {
  CONTACT_US_LINK,
  PRIVACY_POLICY_LINK,
  SUCCESS,
  TERMS_OF_USE,
} from "utils/helpers/constants";
import { toastHandler } from "utils/helpers/toast";

const Signup = () => {
  const [step, setStep] = useState(1);
  const [errorMsg, setErrorMsg] = useState();
  const [formValues, setFormValues] = useState({
    email: "",
    password: "",
    confirmPassword: "",
  });
  const [formErrors, setFormErrors] = useState({});
  const search = useLocation().search;
  const searchParams = new URLSearchParams(search);
  const email = searchParams.get("email");
  console.log({ email });
  const handleChange = ({ target: { name, value } }) => {
    setFormValues((prev) => ({
      ...prev,
      [name]: value,
    }));
    setFormErrors((prev) => ({
      ...prev,
      [name]: "",
    }));
  };

  async function resendEmail() {
    const response = await API.post(resendEmailCode, {
      email: formValues.email || email,
    });
    if (response?.data?.success) {
      toastHandler("Verification Email Sent", SUCCESS);
    }
  }

  function handleSubmit(e) {
    e.preventDefault();
    const { formErrors, isValid } = formValidator({
      email: formValues.email,
      password: formValues.password,
    });
    isValid ? mutate() : setFormErrors(formErrors);
  }

  const { mutate, isLoading } = useMutation(
    async () => {
      setErrorMsg();
      const payload = {
        ...formValues,
      };
      const response = await API.post(initiateSignup, payload);
      return response;
    },
    {
      onSuccess: ({ data, status }) => {
        if (status === 200 && data?.success) {
          setStep(2);
        }
      },
      onError: (error) => {
        setErrorMsg(error?.data?.data?.error);
      },
    }
  );

  useEffect(() => {
    if (email) {
      setStep(2);
      resendEmail();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [email]);

  return (
    <LoginWrapper className=" d-flex">
      <div className="left-div">
        <div className="auth-nav flex__between">
          <Logo className="pointer" />
          <div className="auth-nav-links d-flex">
            <a
              href={PRIVACY_POLICY_LINK}
              to={PRIVACY_POLICY_LINK}
              target="_blank"
              rel="noreferrer"
              className="mx-4"
            >
              Privacy Policy
            </a>
            <a
              to={CONTACT_US_LINK}
              target="_blank"
              rel="noreferrer"
              href={CONTACT_US_LINK}
            >
              Contact Us
            </a>
          </div>
        </div>
        <div className="login-form">
          <div className="px-5 py-4">
            {errorMsg && (
              <Alert message={errorMsg} onClose={() => setErrorMsg()} />
            )}
          </div>
          {step === 1 && (
            <>
              <h2 className="m-0 p-0">Get Started 👋</h2>
              <p className="m-0 text--md">It'll only take few minutes</p>
              <form onSubmit={handleSubmit}>
                <CustomInputBox
                  placeholder="yemi@blusalt.net"
                  error={formErrors?.email}
                  name="email"
                  label="Email"
                  onChange={handleChange}
                  value={formValues?.email}
                />
                <CustomPassword
                  placeholder="Password"
                  error={formErrors?.password}
                  label="Password"
                  name="password"
                  onChange={handleChange}
                  value={formValues?.password}
                  />
                <CustomPassword
                  placeholder="Confirm Password"
                  error={formErrors?.confirmPassword}
                  label="Confirm Password"
                  name="confirmPassword"
                  onChange={handleChange}
                  value={formValues?.confirmPassword}
                />
                <button className="btn btn--primary btn--lg" type="submit">
                  {isLoading ? <CustomSpinner /> : "Continue"}
                </button>
                <p className="text-sm mt-3 text--grey">
                  By clicking "Continue" I agree to Blusalt’s{" "}
                  <a
                    className="text--primary text-underline"
                    to={TERMS_OF_USE}
                    href={TERMS_OF_USE}
                    target="_blank"
                    rel="noreferrer"
                  >
                    Terms of Use
                  </a>
                  ,{" "}
                  <a
                    className="text--primary text-underline"
                    to={PRIVACY_POLICY_LINK}
                    href={PRIVACY_POLICY_LINK}
                    target="_blank"
                    rel="noreferrer"
                  >
                    Privacy Policy
                  </a>
                </p>
                <p className="mt-5 heading-3">
                  Already have an account?{" "}
                  <Link className="text--secondary" to="/login">
                    Login
                  </Link>
                </p>
              </form>
            </>
          )}
          {step === 2 && (
            <>
              <h2 className="m-0 p-0">Verification Link Sent 👋</h2>
              <p className="m-0 text--md">
                Welcome onboard, click on the verification link sent to your
                email at <b>{formValues.email}</b> to verify your account.
              </p>

              <div className="bg-secondary-accent-theta py-5 px-4 mt-5 rounded border-dotted-primary">
                <h4 className="heading-3 mb-5">
                  What do you have to do after your Account Activation?
                </h4>
                <ul>
                  <li className="mb-3 ">
                    <UserTag /> Tell us more about you
                  </li>
                  <li className="mb-3">
                    <Mobile />
                    Authenticate your phone number
                  </li>
                  <li className="mb-3">
                    <Warning />
                    Take a liveness test{" "}
                  </li>
                  <li className="mb-3">
                    <TickCircle />
                    Tell us about where you stay
                  </li>
                </ul>
              </div>
              <p className="mt-5 heading-3">
                Did not get a link?{" "}
                <span className="text--secondary pointer" onClick={resendEmail}>
                  Resend
                </span>
              </p>
            </>
          )}
        </div>
        <div>
          <p className="mt-5">© Blusalt Financial Service Ltd. </p>
        </div>
      </div>
      <div className="right-img-div">
        <img src={blackMadam} alt="black madam" />
      </div>
    </LoginWrapper>
  );
};

export default Signup;
