import SectionWrapper from "utils/section/SectionWrapper/SectionWrapper";
import { ReactComponent as FineBoyAvatar } from "assets/images/icons/fine-boy-lg.svg";
import { tabs } from "../data";
import CustomInputBox from "utils/inputBox/CustomInputBox/CustomInputBox";
import CustomPhoneNumber from "utils/inputBox/CustomPhoneNumber/CustomPhoneNumber";
import CountrySelect from "utils/select/CountrySelect/CountrySelect";
import StateSelect from "utils/select/StateSelect/StateSelect";
import { useContext, useState } from "react";
import API from "api";
import { AuthContext } from "utils/context/AuthContext";
import { Country } from "country-state-city";
import CustomSpinner from "utils/Spinner/Spinner";
import { useMutation } from "react-query";
import { toastHandler } from "utils/helpers/toast";
import { ERROR, SUCCESS } from "utils/helpers/constants";
import { updateUserProfile } from "api/settings";
import { formValidator } from "utils/FormValidator/FormValidator";

const Settings = () => {
  const [formErrors, setFormErrors] = useState({});

  const userProfile = useContext(AuthContext);

  const handleChange = ({ target: { name, value } }) => {
    handleSelect(name, value);
  };

  const handleSelect = (name, value) => {
    setFormValues((prev) => ({
      ...prev,
      [name]: value,
    }));
    setFormErrors((prev) => ({
      ...prev,
      [name]: "",
    }));

    if (name === "country") {
      const phoneCode = `${value.phonecode?.startsWith("+") ? "" : "+"}${
        value?.phonecode
      }`;
      setFormValues((prev) => ({
        ...prev,
        state: null,
        phoneCountryCode: {
          ...value,
          label: `${value?.flag} (${phoneCode})`,
        },
      }));
    }

    if (name === "state") {
      setFormValues((prev) => ({
        ...prev,
      }));
    }
  };
  const country = Country.getAllCountries().find(
    (country) => country?.name === userProfile?.country
  );
  const getState = {
    label: userProfile?.agentInformation?.state,
    value: userProfile?.agentInformation?.state,
  };
  const [formValues, setFormValues] = useState({
    ...userProfile,
    phoneNumber: userProfile?.phoneNumber?.split(" ")[1],
    address1: userProfile?.agentInformation?.address1,
    address2: userProfile?.agentInformation?.address2,
    postalCode: userProfile?.agentInformation?.postalCode,
    city: userProfile?.agentInformation?.city,
    businessName: userProfile?.businessName,
    state: getState,
    country: { ...country, label: country?.name, value: country?.isoCode },
  });

  const { mutate, isLoading } = useMutation(
    async () => {
      const payload = {
        address1: formValues.address1,
        address2: formValues.address2,
        city: formValues.city,
        postalCode: formValues.postalCode,
        businessName: formValues.businessName,
        state: formValues.state.name,
        country: formValues.country.name,
      };
      const response = await API.put(updateUserProfile, payload);
      return response;
    },
    {
      onSuccess: ({ data, status }) => {
        if (status === 200 && data?.success) {
          userProfile.refetch();
          toastHandler("Profile Updated Successfuly", SUCCESS);
        } else {
          toastHandler(data?.data?.success, ERROR);
        }
      },
      onError: (error) => {
        toastHandler(error?.data?.data?.error, ERROR);
      },
    }
  );

  function handleSubmit(e) {
    e.preventDefault();
    const payload = {
      address1: formValues.address1,
      postalCode: formValues.postalCode,
      city: formValues.city,
      businessName: formValues.businessName,
      state: formValues.state?.value,
      country: formValues.country.name,
    };
    const { formErrors, isValid } = formValidator(payload);
    isValid ? mutate() : setFormErrors(formErrors);
  }
  return (
    <>
      <SectionWrapper routeName="Settings" tabs={tabs}>
        <div className="p-5 bg-white rounded-card">
          <h4 className="text--grey fw-600">Representative Information</h4>
          <hr className="text--grey" />
          <div className="flex__center py-5">
            <FineBoyAvatar />
          </div>

          <form action="">
            <div className="row mb-5">
              <div className="col-sm-5">
                <CustomInputBox
                  name="firstName"
                  label="First Name"
                  placeholder="Enter First Name"
                  error={formErrors?.firstName}
                  value={formValues.firstName}
                  disabled
                  onChange={handleChange}
                />
              </div>
              <div className="col-sm-5">
                <CustomInputBox
                  name="lastName"
                  label="Last Name"
                  placeholder="Enter Last Name"
                  value={formValues.lastName}
                  error={formErrors?.lastName}
                  onChange={handleChange}
                  disabled
                />
              </div>
              <div className="col-sm-5">
                <CustomInputBox
                  label="Email Address"
                  placeholder="Enter Email Address"
                  value={formValues.email}
                  disabled
                />
              </div>
              <div className="col-sm-5">
                <CustomPhoneNumber
                  label="Phone Number"
                  dropdownID="phone-code-dropdown"
                  dropDownName="phoneCode"
                  name="phoneNumber"
                  placeholder="Type Here"
                  action={() => {}}
                  dropDownValue={formValues?.phoneCountryCode?.label}
                  value={formValues.phoneNumber}
                  disabled
                />
              </div>
            </div>
            <h4 className="text--grey fw-600">Company Information</h4>
            <hr className="text--grey mb-5" />
            <div className="row">
              <div className="col-sm-5">
                <CustomInputBox
                  name="businessName"
                  label="Business Name"
                  placeholder="Enter Business Name"
                  value={formValues.businessName}
                  error={formErrors?.businessName}
                  onChange={handleChange}
                />
              </div>
              <div className="col-sm-5">
                <CustomInputBox
                  name="address1"
                  label="Business Address"
                  placeholder="Enter Business Address"
                  value={formValues.address1}
                  error={formErrors?.address1}
                  onChange={handleChange}
                />
              </div>
              <div className="col-sm-5">
                <CustomInputBox
                  name="address2"
                  label="Business Address 2"
                  placeholder="Enter Business Address 2"
                  value={formValues.address2}
                  error={formErrors?.address2}
                  onChange={handleChange}
                />
              </div>
              <div className="col-sm-5">
                <CustomInputBox
                  name="city"
                  label="City"
                  placeholder="City"
                  value={formValues.city}
                  error={formErrors?.city}
                  onChange={handleChange}
                />
              </div>
              <div className="col-sm-5">
                <CustomInputBox
                  name="postalCode"
                  label="Postal Code"
                  placeholder="Postal Code"
                  value={formValues.postalCode}
                  error={formErrors?.postalCode}
                  onChange={handleChange}
                />
              </div>
              {/* <div className="col-sm-5">
                <CustomSelect
                  name="businessType"
                  label="Sector"
                  onChange={(value) => handleSelect("businessType", value)}
                  options={[
                    { label: "Agriculture", value: "Agriculture" },
                    { label: "Health", value: "Health" },
                    { label: "Technology", value: "Technology" },
                  ]}
                  placeholder="Select Sector"
                  error={formErrors?.businessType}
                  value={formValues.businessType}
                />
              </div> */}
              <div className="col-sm-5">
                <CountrySelect
                  label="Country"
                  placeholder="Select Country"
                  value={formValues.country}
                  onChange={(value) => handleSelect("country", value)}
                  error={formErrors?.country}
                />
              </div>
              <div className="col-sm-5">
                <StateSelect
                  label="State"
                  placeholder="Select State"
                  countryCode={formValues?.country?.isoCode}
                  value={formValues.state}
                  error={formErrors?.state}
                  onChange={(value) => handleSelect("state", value)}
                />
              </div>
            </div>
            <div className="">
              <button className="btn btn--primary px-4" onClick={handleSubmit}>
                {isLoading ? <CustomSpinner /> : "Submit"}
              </button>
            </div>
          </form>
        </div>
        {/* {showModal && <OTPModal title="Edit Profile" toggle={toggle} />} */}
      </SectionWrapper>
    </>
  );
};

export default Settings;
