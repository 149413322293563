
export const addNewMerchant = "/agent/merchant/invite";
export const resendInvite = "/agent/merchant/resend-invite";
export const getMerchants = `/agent/merchant/list`;
export const getInvites = `/agent/merchant/invite-list`;
export const getSingleMerchant = `/agent/merchant/profile`;
export const getSingleMerchantWallet = (id) =>
  `/auth/partner/merchant/${id}/wallet/balance`;
export const getSingleMerchantWalletInflow = (id) =>
  `/reports/transactions/merchant/${id}/wallet/inflow`;
export const getSingleMerchantTransactions = `/agent/wallet/merchant/transaction-history`