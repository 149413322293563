import { useEffect, useState } from "react";
import { Progress } from "reactstrap";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { dayOptions } from "../../data";
import { DashbaordAreaChartWrapper } from "./DashbaordAreaChartWrapper";
import { useQuery } from "react-query";
import { ReactComponent as UserProfile } from "assets/images/icons/profile-icon.svg";

import { ReactComponent as InfoCircleGray } from "assets/images/icons/info-circle-gray.svg";
import { ReactComponent as CaretSuccess } from "assets/images/icons/caret-success.svg";
import StyledDateSelect from "utils/select/DateSelect/StyledDateSelect";
import { ReactComponent as SmileyProfile } from "assets/images/icons/smiley-icon.svg";
import { ReactComponent as Linegraph } from "assets/images/icons/line-graph.svg";
import API from "api";
import { getActiveMerchants, getTotalMerchants } from "api/dashboard";

function DashbaordAreaChart() {
  const [dateRange, setDateRange] = useState("Today");
  let timer;

  useEffect(() => {
    return () => clearTimeout(timer);
  });

  const getTotalMerchantsQuery = useQuery(
    ["totalMerchants", dateRange],
    async () => {
      const response = await API.get(getActiveMerchants, {
        params: {
          period: dateRange.toLowerCase(),
        },
      });
      return response.data.data;
    },
    {
      keepPreviousData: true,
      refetchOnWindowFocus: false,
    }
  );

  const getSalesQuery = useQuery(
    ["totalSales", dateRange],
    async () => {
      const response = await API.get(getTotalMerchants, {
        params: {
          period: dateRange.toLowerCase(),
        },
      });
      return response.data.data;
    },
    {
      keepPreviousData: true,
      refetchOnWindowFocus: false,
    }
  );

  return (
    <DashbaordAreaChartWrapper className="col-md-6 col-lg-6 p-4 pl-0">
      <div className="bg-white px-0 rounded-card">
        <div className="flex__between p-5 flex-wrap">
          <div>
            <p className="text--grey heading-4">
              Total Clients this {dateRange}{" "}
              <OverlayTrigger
                placement="right"
                overlay={
                  <Tooltip id="tooltip-right">
                    Total Amount of money that was paid into your wallet. This
                    is the sum of all payments plus charges
                  </Tooltip>
                }
              >
                <span className="d-inline-block">
                  <InfoCircleGray className="ms-2 pointer" />
                </span>
              </OverlayTrigger>
            </p>
          </div>
          <StyledDateSelect
            wrapperCls="col-2 col-sm-2"
            cls="col-9 col-sm-10"
            name="date-select"
            formLabel="date-select"
            onChange={(e) => setDateRange(e)}
            options={dayOptions}
            value={dateRange}
          />
        </div>
        <div className="px-5">
          <div className="row border rounded">
            <div className="col-sm-6 p-4">
              <div className=" p-4 rounded">
                <UserProfile />
                <p className="black text--xl text-break mb-0">
                  {getSalesQuery?.data?.merchantCount || 0}
                </p>
                <p className="text--grey heading-4 mt-0">Merchants </p>
                <div className="d-flex">
                  <Linegraph />
                  <div>
                    <p className="black text--sm text-break mb-0 text-success fw-bold">
                      {getSalesQuery?.data?.percentageIncrease || 0}%
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-sm-6 p-4">
              <div className="bg-secondary-accent-theta p-4 rounded">
                <SmileyProfile />
                <p className="black text--xl text-break mb-0">
                  {getTotalMerchantsQuery?.data?.activeMerchants}
                </p>
                <p className="text--grey heading-4 mt-0">Daily Active </p>
                <div className="d-flex">
                  <Linegraph
                    style={{
                      transform: "rotateX(180deg)",
                    }}
                  />
                  <div>
                    <p className="black text--sm text-break mb-0 text-success fw-bold">
                      {getTotalMerchantsQuery?.data?.percentageIncrease || 0}%
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="row px-2">
          <div className="col-6 p-4 pe-2">
            <div className="bg-secondary-accent-theta p-5 rounded">
              <div className="flex__between">
                <div>
                  <p className="text--grey heading-4 mt-0 text--sm m-0">
                    Total Clients this week
                  </p>
                  <p className="black text--xslg text-break m-0 mb-4">
                    {getSalesQuery?.data?.countWeek || 0}
                  </p>
                </div>
                <div>
                  <CaretSuccess />
                </div>
              </div>
              <Progress
                className="col-12 gray mt-3"
                striped
                animated
                color="gray"
                value={((new Date().getDay() + 1) / 7) * 100}
              />
            </div>
          </div>
          <div className="col-6 p-4 ps-2">
            <div className="bg-secondary-accent-theta p-5 rounded">
              <div className="flex__between">
                <div>
                  <p className="text--grey heading-4 mt-0 text--sm m-0">
                    Total Clients this month
                  </p>
                  <p className="black text--xslg text-break m-0 mb-4">
                    {getSalesQuery?.data?.countMonth || 0}
                  </p>
                </div>
                <div>
                  <CaretSuccess />
                </div>
              </div>
              <Progress
                className="col-12 gray mt-3"
                striped
                animated
                color="gray"
                value={
                  (new Date().getDate() /
                    parseInt(
                      new Date(
                        new Date().getFullYear(),
                        new Date().getMonth() + 1,
                        0
                      )
                        .toString()
                        .split(" ")[2]
                    )) *
                  100
                }
              />
            </div>
          </div>
          <div className="col-sm-12 px-4 pb-4">
            <div className="bg-secondary-accent-theta p-5 rounded">
              <div className="flex__between">
                <div>
                  <p className="text--grey heading-4 mt-0 text--sm m-0">
                    Total Clients this year
                  </p>
                  <p className="black text--xslg text-break m-0">
                    {getSalesQuery?.data?.countYear || 0}
                  </p>
                </div>
                <div>
                  <CaretSuccess />
                  {/* <p className="mt-3 mb-0 fw-600 text--md">20%</p> */}
                </div>
              </div>
              <Progress
                className="col-12 gray mt-3"
                striped
                animated
                color="gray"
                value={((new Date().getMonth() + 1) / 12) * 100}
              />
            </div>
          </div>
        </div>
      </div>
    </DashbaordAreaChartWrapper>
  );
}

export default DashbaordAreaChart;
