import CustomToggleSwitch from "utils/CustomToggleSwitch/CustomToggleSwitch";
import SectionWrapper from "utils/section/SectionWrapper/SectionWrapper";
import API from "api";
import { tabs } from "../data";
import { useMutation, useQuery } from "react-query";
import {
  listNotificationSettings,
  updateNotificationSettings,
} from "api/settings";
import BigSpinner from "utils/Spinner/BigSpinner";
import { toastHandler } from "utils/helpers/toast";
import { ERROR, SUCCESS } from "utils/helpers/constants";

const Notification = () => {
  const getNotificationSettingsQuery = useQuery(
    ["notificationSettings"],
    async () => {
      const response = await API.get(listNotificationSettings);
      return response.data;
    },
    {
      keepPreviousData: true,
      refetchOnWindowFocus: false,
    }
  );

  const { mutate } = useMutation(
    async (data) => {
      const response = await API.put(updateNotificationSettings, data);
      return response;
    },
    {
      onSuccess: ({ data, status }) => {
        if (status === 200 && data?.success) {
          getNotificationSettingsQuery.refetch();
          toastHandler("Notifications Settings Updated ", SUCCESS);
        } else toastHandler(data?.message, ERROR);
      },
      onError: (error) => {
        toastHandler(error?.data?.data?.error, ERROR);
      },
    }
  );
  const handleChange = (is_active, _id) => {
    mutate({
      settingId: _id,
      actionType: is_active ? "deactivate" : "activate",
    });
  };
  const { isLoading, data } = getNotificationSettingsQuery;
  return (
    <>
      <SectionWrapper routeName="Notification" tabs={tabs}>
        <div className="p-5 bg-white rounded-card">
          <h4 className="text--grey fw-600">Notification</h4>
          <hr className="text--grey mb-5" />

          <div className="col-7">
            {isLoading ? (
              <div className="w-100 h-500 position-relative flex__column flex__center">
                <BigSpinner />
              </div>
            ) : (
              data.data.map(({ settingId, is_active, _id }) => (
                <div key={_id}>
                  <CustomToggleSwitch
                    handleChange={() => {
                      handleChange(is_active, _id);
                    }}
                    name={settingId?.description}
                    title={settingId?.description}
                    isChecked={is_active}
                  />
                </div>
              ))
            )}
          </div>
        </div>
      </SectionWrapper>
    </>
  );
};

export default Notification;
