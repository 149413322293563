import styled from "styled-components";

export const LoginWrapper = styled.div`
  .text-underline{
    text-decoration: underline;
  }
  .border-dotted-primary{
    border: 1px dotted var(--secondary);
  }
  li{
    list-style: none;
  }
  .otp__input input{
    border-radius: 8px !important;
    border: 1px solid var(--primary) !important;
    height: 50px !important;
    width: 50px !important;
  }
  .form-group-select__control {
    background: inherit !important;
  }
  background-color: var(--faint-gray);

  height: 100vh;
  padding: 20px;
  .right-img-div {
    padding: 3rem;
    img {
      height: -webkit-fill-available;
      width: 120%;
    }
  }
  .left-div {
    width: 60%;
    height: calc(100vh - 8rem);
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    flex-direction: column;
    padding-left: 100px;
    .auth-nav {
      padding: 4rem;
      padding-left: 0;
      width: -webkit-fill-available;
      .auth-nav-links {
        a {
          font-size: 16px;
        }
      }
    }
    .login-form {
      width: 550px;
      h2 {
        font-family: "gilroy-bold";
        font-size: 48px;
      }
      form {
        margin-top: 4rem;
        .form-group .input-wrapper {
          border-radius: 5px;
          padding-top: 3px;
          padding-bottom: 3px;
          input{

              background-color: var(--faint-gray);
          }
        }

        .btn--primary {
          background: var(--primary);
          color: var(--white);
          border-radius: 5px;
          padding-top: 25px;
          padding-bottom: 25px;
          :hover {
            background: var(--secondary);
          }
        }
      }
    }
    .partners-div {
      position: absolute;
      background: white;
      bottom: 100px;
      width: -webkit-fill-available;
      margin-right: 100px;
    }
  }
  @media (max-width: 991.98px) {
    .right-img-div {
      display: none;
    }
    .left-div {
      width: 100%;
      padding-left: 0px;
      .login-form {
        width: 70%;
      }
      .partners-div {
        width: -webkit-fill-available;
        margin-right: 20px;
      }
    }
  @media (max-width: 575.98px) {
    
    .left-div {
      .login-form {
        width: 100%;
      }
    }
  }
`;
