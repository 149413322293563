import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { ReactComponent as InfoCircleGray } from "assets/images/icons/info-circle-gray.svg";
import { ReactComponent as Eye } from "assets/images/icons/eye.svg";
import { ReactComponent as EyeOff } from "assets/images/icons/eye-slash.svg";
import { ReactComponent as Copy } from "assets/images/icons/copy.svg";
import { useState } from "react";
import { useQuery } from "react-query";
import API from "api";
import { getWalletInfo } from "api/wallet";
import BigSpinner from "utils/Spinner/BigSpinner";
import { toCurrency } from "utils/helpers";
import { SUCCESS } from "utils/helpers/constants";
import { toastHandler } from "utils/helpers/toast";

function WalletUpperChat() {
  const [show, setShow] = useState(false);
  const getWalletQuery = useQuery(
    ["wallet"],
    async () => {
      const response = await API.get(getWalletInfo, {});
      return response.data.data;
    },
    {
      keepPreviousData: true,
      refetchOnWindowFocus: false,
    }
  );

  const { isLoading, data } = getWalletQuery;
  const handleCopy = () => {
    console.log(data);
    navigator.clipboard.writeText(
      `Bank Name: ${data?.bank?.bank_name}, Bank Account Name: ${data?.bank?.account_name}, Bank Account Number: ${data?.bank?.account_number}`
    );
    toastHandler("Bank details copied successfully!", SUCCESS);
  };

  return (
    <>
      {isLoading ? (
        <div className="w-100 h-500 position-relative flex__column flex__center">
          <BigSpinner />
        </div>
      ) : (
        <div className="col-md-6">
          <div className=" p-4 pl-0">
            <div className="bg--primary px-0 rounded-card">
              <div className="flex__between p-5 flex-wrap">
                <div>
                  <p className="text--grey-2 heading-4 mt-2 pb-2">
                    Your Wallet Balance
                    <OverlayTrigger
                      placement="right"
                      overlay={
                        <Tooltip id="tooltip-right">
                          Total Amount of money that was paid into your wallet.
                          This is the sum of all payments plus charges
                        </Tooltip>
                      }
                    >
                      <span className="d-inline-block">
                        <InfoCircleGray className="ms-2 pointer" />
                      </span>
                    </OverlayTrigger>
                  </p>
                  <div className="d-flex">
                    <p
                      className={
                        show
                          ? "text--xl text-break  text--grey-2"
                          : "text--xl text-break  blur"
                      }
                    >
                      N{toCurrency(data.balance)}
                    </p>
                    <div
                      className="mt-2 ms-4 pointer"
                      onClick={() => setShow(!show)}
                    >
                      {show ? <Eye /> : <EyeOff />}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className=" p-4 pl-0">
            <div className="bg-white p-4 rounded-card px-5">
              <div className="flex__between m-0">
                <p className="text--grey m-0">Agent Wallet Information</p>
                <span
                  className="copy-div bg-secondary-accent-theta px-3 py-2 m-0 pointer"
                  onClick={handleCopy}
                >
                  Copy Details <Copy />
                </span>
              </div>
              <hr className="text--grey mb-3" />
              <div className="row fw-600">
                <>
                  <div className="col-4 text--grey">Bank Name</div>
                  <div className="col-8 mb-3">{data.bank.bank_name}</div>
                </>
                <>
                  <div className="col-4 text--grey">Bank Account Name</div>
                  <div className="col-8 mb-3">{data.bank.account_name}</div>
                </>
                <>
                  <div className="col-4 text--grey">Bank Account Number</div>
                  <div className="col-8 mb-1">
                    ******
                    {data.bank.account_number.slice(
                      6,
                      data.bank.account_number.length
                    )}
                  </div>
                </>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default WalletUpperChat;
