import { useState } from "react";
import CustomInputBox from "utils/inputBox/CustomInputBox/CustomInputBox";
import { CENTER } from "utils/helpers/constants";
import Modal from "utils/Modal/Modal";
import { formValidator } from "utils/FormValidator/FormValidator";
import { useMutation } from "react-query";
import API from "api";
import { resendInvite } from "api/merchant";
import CustomSpinner from "utils/Spinner/Spinner";
import Alert from "utils/Alert/Alert";
import TaskCompletionStep from "utils/TaskCompletionStep/TaskCompletionStep";

function ResendInviteModal({ toggle, refetch, data }) {
  const [formErrors, setFormErrors] = useState({});
  const [formValues, setFormValues] = useState({
    email: data.email,
  });
  const [successMsg, setSuccessMsg] = useState();
  const [errorMsg, setErrorMsg] = useState();
  const handleSelect = (name, value) => {
    setFormValues((prev) => ({
      ...prev,
      [name]: value,
    }));
    setFormErrors((prev) => ({
      ...prev,
      [name]: "",
    }));
  };

  const handleChange = ({ target: { name, value } }) => {
    handleSelect(name, value);
  };

  const { mutate, isLoading } = useMutation(
    async () => {
      setErrorMsg();
      const payload = {
        ...formValues,
      };
      const response = await API.post(resendInvite, payload);
      return response;
    },
    {
      onSuccess: ({ data, status }) => {
        if (status === 200 && data?.success) {
          setSuccessMsg(data?.message);
          refetch();
        } else {
          setErrorMsg("Unable to send invite");
        }
      },
      onError: (error) => {
        setErrorMsg(error?.data?.data?.error);
      },
    }
  );
  function handleSubmit(e) {
    e.preventDefault();
    const payload = {
      ...formValues,
    };
    const { formErrors, isValid } = formValidator(payload);
    isValid ? mutate() : setFormErrors(formErrors);
  }

  return (
    <Modal
      title="Resend Merchant Invite"
      subtitle=""
      toggle={toggle}
      position={CENTER}
    >
      <div className="px-5 py-4">
        {errorMsg && <Alert message={errorMsg} onClose={() => setErrorMsg()} />}
      </div>
      {successMsg && (
        <TaskCompletionStep title={successMsg}>
          <div className="px-4 py-4">
            <button
              onClick={() => {
                toggle();
                setSuccessMsg();
              }}
              type="button"
              className="btn btn--sm btn--primary"
            >
              Close
            </button>
          </div>
        </TaskCompletionStep>
      )}
      {!successMsg && (
        <div className="px-4 py-50 mx-4">
          <form>
            <CustomInputBox
              label="Email Address"
              placeholder="Enter Email Address"
              error={formErrors?.email}
              name="email"
              onChange={handleChange}
              value={formValues?.email}
              disabled
            />
            <hr className="text--grey my-4" />
            <button className="btn btn--primary px-4" onClick={handleSubmit}>
              {isLoading ? <CustomSpinner /> : "Resend"}
            </button>
          </form>
        </div>
      )}
    </Modal>
  );
}

export default ResendInviteModal;
