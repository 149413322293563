import CustomPassword from "utils/inputBox/CustomInputBox/CustomPassword";
import { CENTER, ERROR, SUCCESS } from "utils/helpers/constants";
import Modal from "utils/Modal/Modal";
import { useState } from "react";
import { useMutation } from "react-query";
import API from "api";
import { resetPassword } from "api/auth";
import { formValidator } from "utils/FormValidator/FormValidator";
import { toastHandler } from "utils/helpers/toast";
import CustomSpinner from "utils/Spinner/Spinner";

function ChangePasswordModal({ toggle }) {
  const [formErrors, setFormErrors] = useState({});
  const [formValues, setFormValues] = useState({
    oldpassword: "",
    password: "",
    confirmPassword: "",
  });

  const handleChange = ({ target: { name, value } }) => {
    setFormValues((prev) => ({
      ...prev,
      [name]: value,
    }));
    setFormErrors((prev) => ({
      ...prev,
      [name]: "",
    }));
  };

  const { mutate, isLoading } = useMutation(
    async () => {
      const response = await API.put(resetPassword, formValues);
      return response;
    },
    {
      onSuccess: ({ data, status }) => {
        if (status === 200 && data?.success) {
          toastHandler("Password Updated Successfully", SUCCESS);
          toggle();
        } else {
          toastHandler("Unable to update password", ERROR);
        }
      },
      onError: (error) => {
        toastHandler(error?.data?.data?.error, ERROR);
      },
    }
  );
  function handleSubmit(e) {
    e.preventDefault();
    const { password, confirmPassword } = formValues;
    if(password === confirmPassword){
      const { formErrors, isValid } = formValidator(formValues);
      isValid ? mutate() : setFormErrors(formErrors);
    } else {
       toastHandler("Password Misamatch", ERROR);
    }
  }
  return (
    <Modal
      title="Change Password"
      subtitle="Create a new password"
      toggle={toggle}
      position={CENTER}
    >
      <div className="px-4 py-50 mx-4">
        <form onSubmit={handleSubmit}>
          <CustomPassword
            label="Enter Current Password"
            name="oldpassword"
            error={formErrors?.oldpassword}
            value={formValues.oldpassword}
            onChange={handleChange}
          />
          <CustomPassword
            label="Enter New Password"
            name="password"
            error={formErrors?.password}
            value={formValues.password}
            onChange={handleChange}
          />
          <CustomPassword
            label="Confirm New Password"
            name="confirmPassword"
            error={formErrors?.confirmPassword}
            value={formValues.confirmPassword}
            onChange={handleChange}
          />

          <hr className="text--grey my-4" />
          <button className="btn btn--primary px-4" type="submit">
            {isLoading ? <CustomSpinner /> : "Change Password"}
          </button>
        </form>
      </div>
    </Modal>
  );
}

export default ChangePasswordModal;
