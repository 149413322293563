import { CENTER, PENDING, SUCCESS } from "utils/helpers/constants";
import Modal from "utils/Modal/Modal";
import { ReactComponent as Avatar } from "assets/images/icons/reciept-logo.svg";
import { timeDateFormat, toCurrency } from "utils/helpers";
import StatusPill from "utils/pills/StatusPill/StatusPill";

const TransactionRecieptModal = ({ toggle, data }) => {
  const { date, time } = timeDateFormat(data?.createdAt);
  return (
    <Modal
      title="Transaction Reciept"
      //   subtitle="Audit Log"
      toggle={toggle}
      position={CENTER}
    >
      <div className="bg-secondary-accent-theta py-50 flex__center flex__column">
        <Avatar />
        <br />
        <h3>
          {data?.currency} {toCurrency(data?.amount || 0)}
        </h3>
        <span className="text--grey">{data?.company}</span>
        <StatusPill
          status={data?.status === "Successful" ? "Successful" : data?.status}
          success={SUCCESS}
          warning={PENDING}
        />
      </div>
      <div className="px-4 py-50 mx-4">
        <div className="row fw-600">
          <>
            <div className="col-12 mb-4">Transaction Details</div>
          </>
          <>
            <div className="col-4 text--grey">Amount</div>
            <div className="col-8 mb-4">
              {data?.currency} {toCurrency(data?.amount || 0)}
            </div>
          </>
          <>
            <div className="col-4 text--grey">Reference Number</div>
            <div className="col-8 mb-4">
              <span className="mt-2">
                {data?.transactionId || data?.transactionReference}
              </span>
            </div>
          </>
          <>
            <div className="col-4 text--grey">Status</div>
            <div className="col-8 mb-4">
              <StatusPill
                status={
                  data?.status === "Successful" ? "Successful" : data?.status
                }
                success={SUCCESS}
                warning={PENDING}
              />
            </div>
          </>
          <>
            <div className="col-4 text--grey">Type</div>
            <div className="col-8 mb-4">{data?.actionType}</div>
          </>
          <>
            <div className="col-4 text--grey">Category</div>
            <div className="col-8 mb-4">{data?.category}</div>
          </>
          <>
            <div className="col-4 text--grey">Transaction Date</div>
            <div className="col-8 mb-4">
              {date} {time}
            </div>
          </>
        </div>
      </div>
    </Modal>
  );
};

export default TransactionRecieptModal;
