import OtpInput from "react-otp-input";

const CustomOTPInput = ({
	name,
	value,
	id,
	separator = "",
	onChange,
	cls,
	numInputs = 6,
	isInputSecure = false,
	isInputNum = true,
}) => (
	<div className={`otp__input text-danger ${cls}`} id={id}>
		<OtpInput
			name={name}
			value={value}
			onChange={onChange}
			numInputs={numInputs}
			separator={separator}
			isInputNum={isInputNum}
			isInputSecure={isInputSecure}
		/>
	</div>
);

export default CustomOTPInput;
