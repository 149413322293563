import React from "react";
import Navlink from "../Navlink/Navlink";
import { ReactComponent as UsersIcon } from "assets/images/icons/logout.svg";
import { ReactComponent as SettingsIcon } from "assets/images/icons/setting.svg";
import { SideNavWrapper } from "./SideNavWrapper";
import { useHistory } from "react-router-dom";
import Cookies from "js-cookie";
import { TOKEN_KEY } from "utils/helpers/constants";

const LowerSideNav = () => {
  const history = useHistory();
  return (
    <SideNavWrapper>
      <ul>
        <Navlink
          link={{
            link: "/settings/agent-profile",
            name: "Settings",
            icon: SettingsIcon,
          }}
        />
        <Navlink
          link={{
            name: "Sign Out",
            icon: UsersIcon,
          }}
          onClick={() => {
            Cookies.remove(TOKEN_KEY);
            history.push("/login");
          }}
        />
      </ul>
    </SideNavWrapper>
  );
};

export default LowerSideNav;
