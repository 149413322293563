import { useState } from "react";
import ReactECharts from "echarts-for-react";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { loadingOption, totalRevChartOptions } from "../../helpers";
import { ReactComponent as InfoCircleGray } from "assets/images/icons/info-circle-gray.svg";
import { InsightChartWrapper } from "./InsightChartWrapper";
import { ReactComponent as InfoSecondary } from "assets/images/icons/info-secondary.svg";
import StyledDateSelect from "utils/select/DateSelect/StyledDateSelect";
import { useQuery } from "react-query";
import API from "api";
import { getCommissionsChart, getCommissionsInsight } from "api/commission";

function InsightChart() {
  const [dateRange, setDateRange] = useState("Daily");
  const [dateRange2, setDateRange2] = useState("Today");

  const getInsightQuery = useQuery(
    ["totalInsight", dateRange2],
    async () => {
      const response = await API.get(getCommissionsInsight, {
        params: {
          period: dateRange2.toLowerCase(),
        },
      });
      return response.data.data;
    },
    {
      keepPreviousData: true,
      refetchOnWindowFocus: false,
    }
  );

  const getRevenueQuery = useQuery(
    ["totalRevenue", dateRange],
    async () => {
      const response = await API.get(getCommissionsChart, {
        params: {
          period: dateRange.toLowerCase(),
        },
      });
      return response.data.data;
    },
    {
      keepPreviousData: true,
      refetchOnWindowFocus: false,
    }
  );

  
  return (
    <InsightChartWrapper className="col-md-12 col-lg-6 p-4 pl-0">
      <div className="bg-white px-0 rounded-card mb-4">
        <div className="flex__between p-5 flex-wrap">
          <div>
            <p className="text--grey heading-4">
              Total Commission{" "}
              <OverlayTrigger
                placement="right"
                overlay={
                  <Tooltip id="tooltip-right">
                    Total Amount of money that was paid into your wallet. This
                    is the sum of all payments plus charges
                  </Tooltip>
                }
              >
                <span className="d-inline-block">
                  <InfoCircleGray className="ms-2 pointer" />
                </span>
              </OverlayTrigger>
            </p>
          </div>
          <StyledDateSelect
            wrapperCls="col-5 col-sm-5"
            cls="col-9 col-sm-10"
            name="date-select"
            formLabel="date-select"
            onChange={(e) => setDateRange(e)}
            options={["Daily", "Monthly", "Yearly"]}
            value={dateRange}
          />
        </div>
        <ReactECharts
          loadingOption={loadingOption}
          option={totalRevChartOptions(getRevenueQuery)}
          opts={{ renderer: "svg" }}
          showLoading={getRevenueQuery.isLoading}
          style={{
            height: "220px",
            minWidth: "100%",
            margin: "auto",
            paddingBottom: "20px",
          }}
        />
      </div>
      <div className="bg--primary p-5 rounded-card text-light">
        <div className="flex__between light-select">
          <div>
            <p className="heading-4">
              <InfoSecondary /> <span className="mx-4">Insight</span>
            </p>
          </div>
          <StyledDateSelect
            wrapperCls="col-9 col-sm-3 "
            cls="col-9 col-sm-10 "
            name="date-select"
            formLabel="date-select"
            onChange={(e) => setDateRange2(e)}
            options={["Today", "Month", "Year"]}
            value={dateRange2}
          />
        </div>
        <div className="d-flex py-2">
          <div>
            <p className="black text--xl text-break">
              {getInsightQuery?.data?.percentageIncrease}%
            </p>
          </div>
          <div className="mx-4">
            <p className="heading-4">
              Increase in <br /> Traffic
            </p>
          </div>
        </div>
        <div>
          <p>{getInsightQuery?.data?.insight}</p>
        </div>
      </div>
    </InsightChartWrapper>
  );
}

export default InsightChart;
