import moment from "moment";
import { DATE_FILTERS } from "./constants";

export const getError = (error) => {
    const errorMessage =
        error && error.message
            ? titleCase(error.message?.replace(/[^a-z0-9+]+/gi, " "))
            : "Error Processing Request. Try again.";

    return errorMessage;
};

export const truncate = (str, len = 50) => {
    if (str?.length > len) {
        return str?.substring(0, len) + "...";
    } else {
        return str;
    }
};

export const appendPlusToNumber = number => {
    if (!number) return;
    const cleanedNumber = number.replace(/\s/g, '').replace(/^\+/, '');

    if (!cleanedNumber) return;

    return `+${cleanedNumber}`;
};

export function removeSpacesExceptFirst(str) {
    return str.replace(/(^\S*)(\s*)(.*)/, function(match, p1, p2, p3) {
        return p1 + (p2 ? ' ' : '') + p3.replace(/\s+/g, '');
    });
}

export const truncateText = (text, newLength = 20) => {
  if (text.length > newLength) {
    const reverseText = text?.split("").reverse().join("");
    const extensionStartIndex = reverseText?.indexOf(".");
    const extension =
      extensionStartIndex !== -1
        ? reverseText.slice(0, extensionStartIndex).split("").reverse().join("")
        : "";
    return `${text.slice(0, newLength - 3)}...${extension}`;
  }

  return text;
};

export const startOf = (date) => moment().startOf(date).format();

export const getDateRange = (dateRange, date) => {
  const to = moment(new Date()).format();
  switch (dateRange) {
    case DATE_FILTERS.DAILY:
      return { from: startOf("day"), to };
    case DATE_FILTERS.WEEKLY:
      return { from: startOf("week"), to };
    case DATE_FILTERS.MONTHLY:
      return { from: moment().subtract(1, "months").format(), to };
    case DATE_FILTERS.CUSTOM:
      return date;
    default:
      return "";
  }
};

export const titleCase = (string) => {
    if (string === null || string === undefined) {
        return "";
    } else {
        let text = string.toLowerCase().split("_");
        for (var i = 0; i < text.length; i++) {
            text[i] = text[i][0]?.toUpperCase() + text[i]?.slice(1);
        }
        return text.join(" ");
    }
};

export const toCurrency = (n) => {
    if (n === null || n === undefined || n === "NaN") return 0;
    return Number(n)
        .toFixed(2)
        .replace(/./g, function (c, i, a) {
            return i > 0 && c !== "." && (a.length - i) % 3 === 0 ? "," + c : c;
        });
};

export const timeDateFormat = (obj, format = "YYYY-MM-DD") => {
    if (!obj || obj === undefined) return "";

    const timeAgo = moment(obj).fromNow();

    const newObj = new Date(obj);

    const fullTime = moment(newObj).format("hh:mm:ss a");
    const time = moment(newObj).format("hh:mm:ss");
    const date = moment(newObj).format(format);

    return { time, date, fullTime, timeAgo };
};

export const timeRangeFormat = (obj, init) => {
    if (!obj) return null;

    const newDate = new Date(obj);
    init ? newDate.setHours(0, 0, 0, 0) : newDate.setHours(23, 59, 59, 0);
    return newDate;
};

export const dateToString = (obj) => {
    if (!obj) return null;

    const newDate = new Date(obj);
    const date = moment(newDate).format("LL");
    return date;
};

