import React from "react";
import DatePicker from "react-datepicker";

import {ReactComponent as DateIcon} from "assets/images/icons/calendar.svg";
import {ReactComponent as ArrowDownIcon} from "assets/images/icons/arrow-down.svg";
// import {ReactComponent as DateColoredIcon} from "assets/images/icons/date-colored.svg";
import {ReactComponent as InfoCircleError} from "assets/images/icons/info-circle-error.svg";

import "react-datepicker/dist/react-datepicker.css";

const CustomDatePicker = ({
  dateFormat = "dd-MM-yyyy",
  label,
  labelCls,
  LabelIcon,
  length,
  min,
  max,
  name,
  placeholder,
  disabled,
  value,
  error,
  onChange,
  dateIconRight,
  dateInputBordered,
  dateIconColored,
  hideDateIcon,
  showIconRight,
  endDate,
  required,
  reference,
  cls,
  selectRange,
  inline,
  ...rest
}) => {
  const isError = error ? " error" : "";
  const isRequired = required ? " required" : "";
  const isDisabled = disabled ? " disabled" : "";
  const isDateInputBordered = dateInputBordered ? " date-input--bordered" : "";

  return (
    <div className={`form-group${isDateInputBordered} ${cls}`}>
      {label && (
        <label
          htmlFor={name}
          className={`form-group-label ${labelCls}${isRequired}`}
        >
          {label}
          {LabelIcon && <LabelIcon className="ms-2 pointer" />}
        </label>
      )}

      <div className="input-container-wrapper date-input">
        <div
          className={`input-wrapper${isDisabled}${isError} d-flex align-items-center`}
        >
          {!dateIconRight && !dateIconColored && !hideDateIcon && (
            <div className="ms-4 date-icon-container">
              <DateIcon />
            </div>
          )}

          {!dateIconRight && dateIconColored && (
            <div className="ms-3 date-icon-container">
              {/* <DateColoredIcon /> */}
            </div>
          )}

          <DatePicker
            name={name}
            dateFormat={dateFormat}
            todayButton="Select Today"
            isClearable
            clearButtonTitle="Clear selected date"
            showMonthDropdown
            showYearDropdown
            dropdownMode="select"
            popperPlacement="auto"
            selected={value}
            selectsRange={selectRange}
            startDate={selectRange ? value : null}
            endDate={endDate ? endDate : null}
            inline={inline}
            minDate={min}
            maxDate={max}
            placeholderText={placeholder}
            disabled={disabled}
            ref={reference}
            className="w-100 pointer"
            {...rest}
            onChange={onChange}
          />

          {dateIconRight && (
            <div className="me-4 date-icon-container">
              <DateIcon />
            </div>
          )}

          {showIconRight && <ArrowDownIcon className="me-3" />}
        </div>

        {typeof error === "string" && error && (
          <small className="input-text-error">
            <InfoCircleError className="me-2" />
            {error}
          </small>
        )}
      </div>
    </div>
  );
};

export default CustomDatePicker;
