import React, { useState } from "react";
import { LoginWrapper } from "./LoginWrapper";
import blackMadam from "assets/images/black-madam.jpg";
import { ReactComponent as Logo } from "assets/images/icons/bluintouch-logo-dark.svg";
import { ReactComponent as LivenessImg } from "assets/images/liveness-check.svg";
import { useHistory, Link } from "react-router-dom";
import API from "api";
import { liveness } from "api/auth";
import Alert from "utils/Alert/Alert";
import CustomSpinner from "utils/Spinner/Spinner";
import {
  CONTACT_US_LINK,
  ERROR,
  PRIVACY_POLICY_LINK,
  SUCCESS,
} from "utils/helpers/constants";
import { useRecordWebcam } from "react-record-webcam";
import { titleCase } from "utils/helpers";
import { toastHandler } from "utils/helpers/toast";
import { useLocation } from "react-router-dom";

const LivenessCheck = () => {
  const history = useHistory();
  const [step, setStep] = useState(1);
  const [errorMsg, setErrorMsg] = useState();
  const [imgLoading, setImgLoading] = useState(false);
  const search = useLocation().search;
  const searchParams = new URLSearchParams(search);
  const email = searchParams.get("email");
  const {
    activeRecordings,
    cancelRecording,
    clearPreview,
    createRecording,
    muteRecording,
    openCamera,
    startRecording,
    stopRecording,
  } = useRecordWebcam();

  const [videoDeviceId] = useState("");
  const [audioDeviceId] = useState("");

  const start = async () => {
    const recording = await createRecording(videoDeviceId, audioDeviceId);
    if (recording) await openCamera(recording.id);
  };

  function blobToFile(theBlob, fileName) {
    return new File([theBlob], fileName, {
      lastModified: new Date().getTime(),
      type: theBlob.type,
    });
  }
  const uploadImg = async (data) => {
    setImgLoading(true);
    const formData = new FormData();
    formData.append("livenessVideo", data);
    formData.append("email", email);

    try {
      const resp = await API.post(liveness, formData);
      if (resp.data.success) {
        toastHandler(titleCase("Liveness Completed"), SUCCESS);
        setTimeout(() => {
          setImgLoading(false);
          history.push("/login");
        }, 2000);
      }
    } catch (error) {
      toastHandler(titleCase(error?.data?.data?.error), ERROR);
      setImgLoading(false);
    }
  };

  return (
    <LoginWrapper className=" d-flex">
      <div className="left-div">
        <div className="auth-nav flex__between">
          <Logo className="pointer" />
          <div className="auth-nav-links d-flex">
            <a
              href={PRIVACY_POLICY_LINK}
              to={PRIVACY_POLICY_LINK}
              target="_blank"
              rel="noreferrer"
              className="mx-4"
            >
              Privacy Policy
            </a>
            <a
              to={CONTACT_US_LINK}
              target="_blank"
              rel="noreferrer"
              href={CONTACT_US_LINK}
            >
              Contact Us
            </a>
          </div>
        </div>
        <div className="login-form">
          <div className="px-5 py-4">
            {errorMsg && (
              <Alert message={errorMsg} onClose={() => setErrorMsg()} />
            )}
          </div>
          <>
            <h2 className="m-0 p-0">Liveness Check 📸 </h2>
            <p className="m-0 text--md">It'll only take few minutes</p>
            <form>
              {step === 1 && (
                <>
                  <div className="my-5 pb-5">
                    <LivenessImg className="my-5" />
                    <p>Face forward and make sure your eyes are well visible</p>
                  </div>
                  <button
                    className="btn btn--primary btn--lg"
                    type="button"
                    onClick={() => {
                      setStep(2);
                      start();
                    }}
                  >
                    Continue
                  </button>

                  <p className="mt-5 heading-3">
                    Already have an account?{" "}
                    <Link className="text--secondary" to="/login">
                      Login
                    </Link>
                  </p>
                </>
              )}
              {step === 2 && (
                <>
                  <div>
                    <div className="devices">
                      {activeRecordings?.map((recording) => (
                        <div className="device" key={recording.id}>
                          {/* <div className="device-list">
                            <small>Status: {recording.status}</small>
                            <small>Video: {recording.videoLabel}</small>
                            <small>Audio: {recording.audioLabel}</small>
                          </div> */}
                          <div
                            className="flex__center flex-wrap"
                            style={{
                              display: "flex",
                              flexDirection:
                                recording.status === "STOPPED"
                                  ? "column-reverse"
                                  : "column",
                            }}
                          >
                            <div
                              style={{
                                opacity: recording.status === "STOPPED" ? 0 : 1,
                              }}
                            >
                              <p>Live</p>
                              <video
                                ref={recording.webcamRef}
                                loop
                                autoPlay
                                playsInline
                                className=" cam-video"
                                muted
                              />
                              <div className="flex__between">
                                <button
                                  className="btn btn--primary btn--lg me-1"
                                  disabled={recording.status === "RECORDING"}
                                  type="button"
                                  onClick={() => {
                                    startRecording(recording.id);
                                    muteRecording(recording.id);
                                    setTimeout(() => {
                                      stopRecording(recording.id);
                                    }, 3000);
                                  }}
                                >
                                  {recording.status === "RECORDING"
                                    ? "Processing..."
                                    : "Start"}
                                </button>
                                <button
                                  type="button"
                                  className="btn btn--primary btn--sm ms-1"
                                  onClick={() => {
                                    cancelRecording(recording.id);
                                    setStep(1);
                                  }}
                                >
                                  Cancel
                                </button>
                              </div>
                            </div>

                            <div
                              className="preview"
                              style={{
                                opacity: recording.status === "STOPPED" ? 1 : 0,
                              }}
                            >
                              <p>Preview</p>
                              <video
                                ref={recording.previewRef}
                                autoPlay
                                loop
                                playsInline
                                className="cam-video"
                                muted
                              />
                              <div className="flex__between">
                                <button
                                  type="button"
                                  className="btn btn--primary btn--lg me-1"
                                  onClick={async () => {
                                    const videoBlob = await fetch(
                                      recording.objectURL
                                    ).then((e) => e.blob());
                                    console.log({
                                      recording,
                                      videoBlob,
                                      blobToFile: blobToFile(videoBlob, "test"),
                                    });
                                    uploadImg(blobToFile(videoBlob, "test"));
                                  }}
                                >
                                  {imgLoading ? <CustomSpinner /> : "Confirm"}
                                </button>
                                <button
                                  type="button"
                                  className="btn btn--primary btn--lg ms-1"
                                  onClick={() => clearPreview(recording.id)}
                                >
                                  Redo
                                </button>
                                {/* <button
                                  className="btn btn--primary btn--lg ms-1"
                                  onClick={async () => {
                                    const videoBlob = await fetch(
                                      recording.objectURL
                                    ).then((e) => e.blob());
                                    const a = document.createElement("a");
                                    document.body.appendChild(a);
                                    a.style = "display: none";
                                    a.href = recording.objectURL;
                                    a.download = "test.mp4";
                                    a.click();
                                    console.log({
                                      recording,
                                      videoBlob,
                                      blobToFile: blobToFile(videoBlob, "test"),
                                    });
                                  }}
                                >
                                  Log
                                </button> */}
                              </div>
                            </div>
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>

                  <p className="mt-5 heading-3">
                    Already have an account?{" "}
                    <Link className="text--secondary" to="/login">
                      Login
                    </Link>
                  </p>
                </>
              )}
            </form>
          </>
        </div>
        <div>
          <p className="mt-5">© Blusalt Financial Service Ltd. </p>
        </div>
      </div>
      <div className="right-img-div">
        <img src={blackMadam} alt="black madam" />
      </div>
    </LoginWrapper>
  );
};

export default LivenessCheck;
