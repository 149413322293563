import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { LoginWrapper } from "./LoginWrapper";
import { formValidator } from "utils/FormValidator/FormValidator";
import blackMadam from "assets/images/black-madam.jpg";
import { ReactComponent as Logo } from "assets/images/icons/bluintouch-logo-dark.svg";
import { useHistory, Link } from "react-router-dom";
import CustomInputBox from "utils/inputBox/CustomInputBox/CustomInputBox";
import API from "api";
import {
  addressVerification,
  agentImageUpload,
  identityTypes,
  identityVerification,
  phoneVerification,
  resendPhone,
  verifyEmailCode,
  verifyPhone,
} from "api/auth";
import Alert from "utils/Alert/Alert";
import CustomSpinner from "utils/Spinner/Spinner";
import {
  CONTACT_US_LINK,
  ERROR,
  PRIVACY_POLICY_LINK,
  SUCCESS,
} from "utils/helpers/constants";
import VerifyOTPModal from "./modal/VerifyOTPModal";
import VerifyAppAuthModal from "./modal/VerifyAppAuthModal";
import CustomDatePicker from "utils/CustomDatePicker/CustomDatePicker";
import CustomOTPInput from "utils/inputBox/CustomOTPInput/CustomOTPInput";
import CustomSelect from "utils/select/CustomSelect/CustomSelect";
import { FileUploadSelect } from "utils/fileUpload/FileUploadSelect/FileUploadSelect";
import { useParams } from "react-router-dom";
import { toastHandler } from "utils/helpers/toast";
import {
  appendPlusToNumber,
  removeSpacesExceptFirst,
  titleCase,
} from "utils/helpers";
import CustomPhoneInput from "utils/inputBox/CustomPhoneInput/CustomPhoneInput";
import { parsePhoneNumber } from "libphonenumber-js";
import CountrySelect from "utils/select/CountrySelect/CountrySelect";
import StateSelect from "utils/select/StateSelect/StateSelect";

const VerifyEmail = () => {
  const [isLoading, setIsLoading] = useState(false);
  const history = useHistory();
  const [errorMsg, setErrorMsg] = useState();
  const [OTP, setOTP] = useState();
  const [imgLoading, setImgLoading] = useState(false);
  const [formValues, setFormValues] = useState({
    email: "",
    password: "",
    phoneNumber: "",
    firstName: "",
    lastName: "",
    dateOfBirth: "",
    identityExpiry: "",
    identityType: "",
    identityNumber: "",
    address1: "",
    address2: "",
    city: "",
    state: "",
    country: "",
    addressProof: "",
    postalCode: "",
  });
  const [identities, setIdentities] = useState([]);
  const [step, setStep] = useState(1);
  const [showModal, setShowModal] = useState(false);
  const [showAppModal, setShowAppModal] = useState(false);
  const [formErrors, setFormErrors] = useState({});
  const [userId] = useState("");
  const { otp } = useParams();
  const search = useLocation().search;
  const searchParams = new URLSearchParams(search);
  const email = searchParams.get("email");
  const stage = searchParams.get("step");
  const toggle = () => {
    setShowModal((prev) => !prev);
  };
  const toggleAppModal = () => {
    setShowAppModal((prev) => !prev);
  };
  const handleChange = ({ target: { name, value } }) => {
    setFormValues((prev) => ({
      ...prev,
      [name]: value,
    }));
    setFormErrors((prev) => ({
      ...prev,
      [name]: "",
    }));
  };

  const handleSelect = (name, value) => {
    setFormValues((prev) => ({
      ...prev,
      [name]: value,
    }));
    setFormErrors((prev) => ({
      ...prev,
      [name]: "",
    }));
    // if (name === "addressProof") {
    //   uploadImg(value[0]);
    // }
  };

  const uploadImg = async (name, value) => {
    const data = value[0];
    setImgLoading(true);
    const formData = new FormData();
    formData.append("imageFile", data);

    if (data.size / (1024 * 1000) < 5) {
      try {
        const resp = await API.post(agentImageUpload, formData);

        if (resp.data.success) {
          setFormValues((prev) => ({
            ...prev,
            addressProof: resp.data.data.url,
          }));
          setImgLoading(false);
        }
      } catch (error) {
        toastHandler(titleCase(error?.data?.data?.error), ERROR);
        setImgLoading(false);
      }
    } else {
      setFormErrors((prev) => ({
        ...prev,
        addressProof: "Image must be less than 5mb",
      }));
    }
  };

  async function verifyEmail() {
    if (!email) {
      try {
        setIsLoading(true);
        const response = await API.post(verifyEmailCode, { token: otp });
        if (response?.data?.data?.email) {
          toastHandler("Email Sucessfully Verified", SUCCESS);
          setFormValues((prev) => ({
            ...prev,
            email: response?.data?.data?.email,
          }));
          setIsLoading(false);
          setStep(2);
        } else {
          setIsLoading(false);
          toastHandler("Unable to verify Email", ERROR);
        }
      } catch (error) {
        setIsLoading(false);
        toastHandler("Unable to verify Email", ERROR);
      }
    }
  }

  async function registerPhone(e) {
    e.preventDefault();
    const { formErrors, isValid } = formValidator({
      email: formValues.email,
      firstName: formValues.firstName,
      lastName: formValues.lastName,
      dateOfBirth: formValues.dateOfBirth,
    });

    if (isValid) {
      try {
        setIsLoading(true);
        const phoneNumber = parsePhoneNumber(
          appendPlusToNumber(formValues.phoneNumber)
        );
        const response = await API.post(phoneVerification, {
          email: formValues.email,
          phoneNumber: removeSpacesExceptFirst(
            phoneNumber.formatInternational()
          ),
          firstName: formValues.firstName,
          lastName: formValues.lastName,
          dateOfBirth: formValues.dateOfBirth,
        });
        if (response?.data?.data?.email) {
          setIsLoading(false);
          setStep(3);
        }
      } catch (error) {
        setIsLoading(false);
        toastHandler(titleCase(error?.data?.data?.error), ERROR);
      }
    } else {
      setFormErrors(formErrors);
    }
  }

  async function resendPhoneCode() {
    const phoneNumber = parsePhoneNumber(
      appendPlusToNumber(formValues.phoneNumber)
    );
    const response = await API.post(resendPhone, {
      phoneNumber: removeSpacesExceptFirst(phoneNumber.formatInternational()),
      email: formValues.email,
    });
    if (response?.data?.success) {
      toastHandler("Verification Code Sent", SUCCESS);
    }
  }

  async function verifyPhoneNumber(e) {
    e.preventDefault();
    try {
      setIsLoading(true);
      const response = await API.post(verifyPhone, {
        token: OTP,
      });
      if (response?.data?.data?.email) {
        setIsLoading(false);
        toastHandler("Phone Number Sucessfully Verified", SUCCESS);
        setStep(4);
      } else {
        setIsLoading(false);
        toastHandler("Unable to verify Phone Number", ERROR);
      }
    } catch (error) {
      setIsLoading(false);
      toastHandler("Unable to verify Phone Number", ERROR);
    }
  }

  async function fetchIdentityTypes() {
    const response = await API.get(identityTypes);
    setIdentities(response.data.data);
  }

  async function verifyIdentity(e) {
    e.preventDefault();
    const { formErrors, isValid } = formValidator({
      email: formValues.email,
      identityType: formValues.identityType,
      identityNumber: formValues.identityNumber,
    });
    if (isValid) {
      try {
        setIsLoading(true);
        const payload = {
          email: formValues.email,
          identityType: formValues.identityType.value,
          identityNumber: formValues.identityNumber,
          identityExpiry: formValues.identityExpiry,
        };

        if (!payload.identityExpiry) delete payload.identityExpiry;

        const response = await API.post(identityVerification, payload);
        if (response?.data?.data?.email) {
          setIsLoading(false);
          setStep(5);
        } else {
          setIsLoading(false);
        }
      } catch (error) {
        setIsLoading(false);
        toastHandler(titleCase(error?.data?.data?.error), ERROR);
      }
    } else {
      setFormErrors(formErrors);
    }
  }

  async function verifyAddress(e) {
    e.preventDefault();
    const { formErrors, isValid } = formValidator({
      email: formValues.email,
      address1: formValues.address1,
      city: formValues.city,
      state: formValues.state,
      country: formValues.country,
      addressProof: formValues.addressProof,
      postalCode: formValues.postalCode,
    });
    if (isValid) {
      try {
        setIsLoading(true);
        const payload = {
          email: formValues.email,
          address1: formValues.address1,
          address2: formValues.address2,
          city: formValues.city,
          state: formValues.state.name,
          country: formValues.country.name,
          addressProof: formValues.addressProof,
          postalCode: formValues.postalCode,
        };

        if (!formValues.address2) delete payload.address2;
        const response = await API.post(addressVerification, payload);
        if (response?.data?.data?.email) {
          history.push(`/liveness-check?email=${response?.data?.data?.email}`);
        }
        setIsLoading(false);
      } catch (error) {
        setIsLoading(false);
        toastHandler(titleCase(error?.data?.data?.error), ERROR);
      }
    } else {
      setFormErrors(formErrors);
    }
  }

  useEffect(() => {
    verifyEmail();
    fetchIdentityTypes();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (email && stage) {
      setFormValues((prev) => ({
        ...prev,
        email: email,
      }));
      setStep(parseInt(stage));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [email]);

  return (
    <LoginWrapper className=" d-flex">
      <div className="left-div">
        <div className="auth-nav flex__between">
          <Logo className="pointer" />
          <div className="auth-nav-links d-flex">
            <a
              href={PRIVACY_POLICY_LINK}
              to={PRIVACY_POLICY_LINK}
              target="_blank"
              rel="noreferrer"
              className="mx-4"
            >
              Privacy Policy
            </a>
            <a
              to={CONTACT_US_LINK}
              target="_blank"
              rel="noreferrer"
              href={CONTACT_US_LINK}
            >
              Contact Us
            </a>
          </div>
        </div>
        <div className="login-form">
          <div className="px-5 py-4">
            {errorMsg && (
              <Alert message={errorMsg} onClose={() => setErrorMsg()} />
            )}
          </div>
          {step === 1 && (
            <>
              <h2 className="m-0 p-0">Verify your email ✉️</h2>
              <p className="m-0 text--md">Enter the code sent to your email</p>
              <form>
                <div className=" pb-5">
                  <CustomOTPInput value={otp} />
                </div>
                <button
                  className="btn btn--primary btn--lg"
                  type="button"
                  disabled
                >
                  {isLoading ? <CustomSpinner /> : "Verify"}
                </button>
              </form>
            </>
          )}
          {step === 2 && (
            <>
              <form onSubmit={registerPhone}>
                <div className="row">
                  <div className="col-6">
                    <CustomInputBox
                      placeholder="First Name"
                      error={formErrors?.firstName}
                      name="firstName"
                      label="First Name"
                      onChange={handleChange}
                      value={formValues?.firstName}
                      required
                    />
                  </div>
                  <div className="col-6">
                    <CustomInputBox
                      placeholder="Last Name"
                      label="Last Name"
                      error={formErrors?.lastName}
                      name="lastName"
                      onChange={handleChange}
                      value={formValues?.lastName}
                      required
                    />
                  </div>
                </div>
                <CustomPhoneInput
                  type="number"
                  error={formErrors?.phoneNumber}
                  label="Phone Number"
                  name="phoneNumber"
                  onChange={(value) => handleSelect("phoneNumber", value)}
                  placeholder="E.g 80********"
                  value={formValues?.phoneNumber}
                  required
                  countryCodeEditable={false}
                />
                <CustomDatePicker
                  placeholder="Date of birth"
                  name="dateOfBirth"
                  label="Date Of Birth"
                  onChange={(e) => handleSelect("dateOfBirth", e)}
                  error={formErrors?.dateOfBirth}
                  value={formValues?.dateOfBirth}
                  required
                />
                <button className="btn btn--primary btn--lg" type="submit">
                  {isLoading ? <CustomSpinner /> : "Continue"}
                </button>
                <p className="mt-5 heading-3">
                  Already have an account?{" "}
                  <Link className="text--secondary" to="/login">
                    Login
                  </Link>
                </p>
              </form>
            </>
          )}
          {step === 3 && (
            <>
              <h2 className="m-0 p-0">Verify your number ☎️</h2>
              <p className="m-0 text--md">
                Enter the code sent to your mobile number
              </p>
              <form onSubmit={verifyPhoneNumber}>
                <div className=" pb-5">
                  <CustomOTPInput
                    value={OTP}
                    onChange={(value) => setOTP(value)}
                    name="smsOtp"
                  />
                </div>
                <button className="btn btn--primary btn--lg" type="submit">
                  {isLoading ? <CustomSpinner /> : "Verify"}
                </button>
                <p className="mt-5 heading-3">
                  Didn't get code?{" "}
                  <span
                    className="text--secondary pointer"
                    onClick={resendPhoneCode}
                  >
                    Resend
                  </span>
                </p>
              </form>
            </>
          )}
          {step === 4 && (
            <>
              <form onSubmit={verifyIdentity}>
                <CustomSelect
                  name="identityType"
                  onChange={(value) => handleSelect("identityType", value)}
                  options={identities.map((dt) => ({
                    label: Object.values(dt)[0],
                    value: Object.keys(dt)[0],
                  }))}
                  placeholder="Select ID Type"
                  label="ID Type"
                  value={formValues?.identityType}
                  error={formErrors?.identityType}
                />
                <CustomInputBox
                  placeholder="Enter ID Number"
                  label="ID Number"
                  error={formErrors?.identityNumber}
                  name="identityNumber"
                  onChange={handleChange}
                  value={formValues?.identityNumber}
                />
                <CustomDatePicker
                  placeholder="Expiry"
                  label="Expiry"
                  name="identityExpiry"
                  onChange={(e) => handleSelect("identityExpiry", e)}
                  error={formErrors?.identityExpiry}
                  value={formValues?.identityExpiry}
                  required
                />
                <button className="btn btn--primary btn--lg" type="submit">
                  {isLoading ? <CustomSpinner /> : "Continue"}
                </button>
                <p className="mt-5 heading-3">
                  Already have an account?{" "}
                  <Link className="text--secondary" to="/login">
                    Login
                  </Link>
                </p>
              </form>
            </>
          )}
          {step === 5 && (
            <>
              <form onSubmit={verifyAddress}>
                <CustomInputBox
                  label="Address 1"
                  placeholder="Address 1"
                  error={formErrors?.address1}
                  name="address1"
                  onChange={handleChange}
                  value={formValues?.address1}
                />
                <CustomInputBox
                  label="Address 2"
                  placeholder="Address 2"
                  error={formErrors?.address2}
                  name="address2"
                  onChange={handleChange}
                  value={formValues?.address2}
                />
                <CountrySelect
                  label="Select Country"
                  placeholder="Select Country"
                  error={formErrors?.country}
                  onChange={(value) => handleSelect("country", value)}
                  value={formValues?.country}
                />
                <StateSelect
                  label="Select State"
                  placeholder="Select State"
                  countryCode={formValues?.country?.isoCode}
                  error={formErrors?.state}
                  onChange={(value) => handleSelect("state", value)}
                  value={formValues?.state}
                />
                <CustomInputBox
                  label="City"
                  placeholder="City"
                  error={formErrors?.city}
                  name="city"
                  onChange={handleChange}
                  value={formValues?.city}
                />
                <CustomInputBox
                  label="Postal Code"
                  placeholder="Postal Code"
                  error={formErrors?.postalCode}
                  name="postalCode"
                  onChange={handleChange}
                  value={formValues?.postalCode}
                />
                <div className="d-flex ">
                  <div className="w-100">
                    <FileUploadSelect
                      label="Proof of Address"
                      name="addressProof"
                      showInput
                      error={formErrors?.addressProof}
                      handleSelect={uploadImg}
                      accept=".jpg,.png,.jpeg"
                    />
                  </div>
                  {imgLoading && <CustomSpinner cls="text--primary" />}
                </div>
                <button
                  className="btn btn--primary btn--lg"
                  type="submit"
                  disabled={!formValues.addressProof}
                >
                  {isLoading ? <CustomSpinner /> : "Continue"}
                </button>
                <p className="mt-5 heading-3">
                  Already have an account?{" "}
                  <Link className="text--secondary" to="/login">
                    Login
                  </Link>
                </p>
              </form>
            </>
          )}
        </div>
        <div>
          <p className="mt-5">© Blusalt Financial Service Ltd. </p>
        </div>
      </div>
      <div className="right-img-div">
        <img src={blackMadam} alt="black madam" />
      </div>
      {showModal && <VerifyOTPModal toggle={toggle} />}
      {showAppModal && (
        <VerifyAppAuthModal toggle={toggleAppModal} userId={userId} />
      )}
    </LoginWrapper>
  );
};

export default VerifyEmail;
