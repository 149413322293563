import StyledDateSelect from "utils/select/DateSelect/StyledDateSelect";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import API from "api";
import { ReactComponent as InfoCircleGray } from "assets/images/icons/info-circle-gray.svg";
import { useEffect, useState } from "react";
import { useQuery } from "react-query";
import ReactECharts from "echarts-for-react";
import { loadingOption, totalComChartOptions } from "../../helpers";
import { getActiveMerchantGraph } from "api/dashboard";

function WalletLowerChat() {
  const [dateRange, setDateRange] = useState("Week");

  let timer;
  useEffect(() => {
    return () => clearTimeout(timer);
  });
  const getActiveMerchantGraphs = useQuery(
    ["activeMerchantGraph", dateRange],
    async () => {
      const response = await API.get(getActiveMerchantGraph, {
        params: {
          period: dateRange.toLowerCase(),
        },
      });
      return response.data.data;
    },
    {
      keepPreviousData: true,
      refetchOnWindowFocus: false,
    }
  );
  function onChartReady(echarts) {
    timer = setTimeout(function () {
      echarts.hideLoading();
    }, 3000);
  }
  return (
    <div className="col-md-6 col-lg-6 p-4 pl-0">
      <div className="bg-white px-0 rounded-card">
        <div className="p-5 flex-wrap">
          <div className="flex__between ">
            <p className="text--grey heading-4">
              Average Transactions{" "}
              <OverlayTrigger
                placement="right"
                overlay={
                  <Tooltip id="tooltip-right">Average Transactions</Tooltip>
                }
              >
                <span className="d-inline-block">
                  <InfoCircleGray className="ms-2 pointer" />
                </span>
              </OverlayTrigger>
            </p>
            <StyledDateSelect
              wrapperCls="col-2 col-sm-2"
              cls="col-9 col-sm-10"
              name="date-select"
              formLabel="date-select"
              onChange={(e) => setDateRange(e)}
              value={dateRange}
              options={["Week", "Year"]}
            />
          </div>
          <ReactECharts
            loadingOption={loadingOption}
            onChartReady={onChartReady}
            option={totalComChartOptions(getActiveMerchantGraphs?.data)}
            opts={{ renderer: "svg" }}
            // showLoading={true}
            style={{
              height: "260px",
              minWidth: "100%",
              margin: "auto",
            }}
          />
        </div>
      </div>
    </div>
  );
}

export default WalletLowerChat;
