const route = "/settings";

export const tabs = [
  { label: "Agent Profile", route: `${route}/agent-profile` },
  { label: "Payment", route: `${route}/payment` },
  { label: "Security", route: `${route}/security` },
  { label: "Notification", route: `${route}/notification` },
];


export const DELETE = "DELETE";
export const BLOCK = "BLOCK";
export const CHANGE = "CHANGE";
export const RESEND = "RESEND";
export const INVITE = "INVITE";


export const teamTableHeadings = [
  "Email Address",
  "Name",
  "Role",
  "Date Joined",
];

export const teamFiltersDefaultValues = {
  "status[0]": null,
  "status[1]": null,
  "status[2]": null,
  "action[0]": null,
  "action[1]": null,
};

export const teamTableData = [
  {
    email: "yemi@blusalt.com",
    name: "Yemi Williams",
    role: "Super Admin",
    status: "Active",
    date: "12 Feb. 2022, 13:27:06 GMT",
    lastActive: "1 minute ago",
  },
  {
    email: "yemi@blusalt.com",
    name: "Yemi Williams",
    role: "Super Admin",
    status: "Pending",
    date: "12 Feb. 2022, 13:27:06 GMT",
    lastActive: "1 minute ago",
  },
  {
    email: "yemi@blusalt.com",
    name: "Yemi Williams",
    role: "Super Admin",
    status: "Active",
    date: "12 Feb. 2022, 13:27:06 GMT",
    lastActive: "1 minute ago",
  },
  {
    email: "yemi@blusalt.com",
    name: "Yemi Williams",
    role: "Super Admin",
    status: "Active",
    date: "12 Feb. 2022, 13:27:06 GMT",
    lastActive: "1 minute ago",
  },
  {
    email: "yemi@blusalt.com",
    name: "Yemi Williams",
    role: "Super Admin",
    status: "Active",
    date: "12 Feb. 2022, 13:27:06 GMT",
    lastActive: "1 minute ago",
  },
  {
    email: "yemi@blusalt.com",
    name: "Yemi Williams",
    role: "Super Admin",
    status: "Pending",
    date: "12 Feb. 2022, 13:27:06 GMT",
    lastActive: "1 minute ago",
  },
  {
    email: "yemi@blusalt.com",
    name: "Yemi Williams",
    role: "Super Admin",
    status: "Active",
    date: "12 Feb. 2022, 13:27:06 GMT",
    lastActive: "1 minute ago",
  },
  {
    email: "yemi@blusalt.com",
    name: "Yemi Williams",
    role: "Super Admin",
    status: "Active",
    date: "12 Feb. 2022, 13:27:06 GMT",
    lastActive: "1 minute ago",
  },
  {
    email: "yemi@blusalt.com",
    name: "Yemi Williams",
    role: "Super Admin",
    status: "Active",
    date: "12 Feb. 2022, 13:27:06 GMT",
    lastActive: "1 minute ago",
  },
  {
    email: "yemi@blusalt.com",
    name: "Yemi Williams",
    role: "Super Admin",
    status: "Active",
    date: "12 Feb. 2022, 13:27:06 GMT",
    lastActive: "1 minute ago",
  },
  {
    email: "yemi@blusalt.com",
    name: "Yemi Williams",
    role: "Super Admin",
    status: "Active",
    date: "12 Feb. 2022, 13:27:06 GMT",
    lastActive: "1 minute ago",
  },
];
